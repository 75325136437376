import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import Toast from 'components/atoms/Toast'

import PatientForm from 'components/dme/forms/PatientForm'
import { formatUserPayload } from 'components/dme/forms/utils/userFormUtils'
import { phoneValidation, requiredPhoneValidation } from 'helpers/validate'
import { useUpdatePatientMutation } from 'store/services/patient'
import {
  useUpdateLabOrderPatientMutation,
  useUpdateLabPatientMutation,
} from 'store/services/labPatient'
import { FormikDirtyStateSync } from 'components/atoms/FormikDirtyStateSync'
import { Order, Patient } from 'store/types'

interface IProps {
  patient: Patient
  orderId?: string
  handleView?: any
  route?: any
  isNewOrder?: boolean
  actionButtons?: any
  modalView: boolean
  labUser?: boolean
  setFormDirty?: (d: boolean) => void
  locked?: boolean
  postSubmitAction?: () => void
  order?: Order
  unlockedForm?: boolean
}

const PatientUpsert = ({
  patient,
  orderId,
  handleView,
  route,
  isNewOrder,
  actionButtons,
  modalView,
  labUser = false,
  locked = false,
  setFormDirty = () => {},
  postSubmitAction = () => {},
  order,
  unlockedForm = false,
}: IProps) => {
  const navigate = useNavigate()
  const { id } = patient || {}
  const [updatePatient, { isLoading }] = useUpdatePatientMutation()
  const [updateLabPatient] = useUpdateLabPatientMutation()
  const [updateLabOrderPatient] = useUpdateLabOrderPatientMutation()

  const initialValues: Patient = {
    firstName: patient?.firstName ?? '',
    lastName: patient?.lastName ?? '',
    middleName: patient?.middleName ?? '',
    suffix: patient?.suffix ?? '',
    dob: moment(patient?.dob).format('MM/DD/YYYY') ?? '',
    gender: patient?.gender ?? '',
    language: patient?.language ?? '',
    ssn: patient?.ssn ?? '',
    homePhone: patient?.homePhone?.replace(/^\+1/, '') ?? '',
    mobilePhone: patient?.mobilePhone?.replace(/^\+1/, '') ?? '',
    email: patient?.email ?? '',
    heightFt: patient?.heightFt ?? '',
    heightIn: patient?.heightIn ?? '',
    weight: patient?.weight ?? '',
    address: patient?.primaryAddressStreetLine1 ?? '',
    primaryAddressStreetLine1: patient?.primaryAddressStreetLine1 ?? '',
    primaryAddressStreetLine2: patient?.primaryAddressStreetLine2 ?? '',
    primaryAddressCity: patient?.primaryAddressCity ?? '',
    primaryAddressState: patient?.primaryAddressState ?? '',
    primaryAddressZip: patient?.primaryAddressZip ?? '',
    deliverySameAsPrimary: patient?.deliverySameAsPrimary ?? true,
    mail_address: patient?.deliveryAddressStreetLine1 ?? '',
    deliveryAddressStreetLine1: patient?.deliveryAddressStreetLine1 ?? '',
    deliveryAddressStreetLine2: patient?.deliveryAddressStreetLine2 ?? '',
    deliveryAddressCity: patient?.deliveryAddressCity ?? '',
    deliveryAddressState: patient?.deliveryAddressState ?? '',
    deliveryAddressZip: patient?.deliveryAddressZip ?? '',
  }

  const userValidation = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    dob: Yup.date().required('Required'),
    gender: Yup.string().required('Required'),
    homePhone: requiredPhoneValidation,
    mobilePhone: phoneValidation,
    email: Yup.string().email('Invalid Email'),
    primaryAddressStreetLine1: Yup.string().required('Required'),
    primaryAddressCity: Yup.string().required('Required'),
    primaryAddressState: Yup.string().required('Required'),
    primaryAddressZip: Yup.string()
      .required('Required')
      .test('len', 'Must be 5 or 9 digits', (val) => {
        const digits = val?.replace(/[^\d]/g, '')
        return digits?.length === 5 || digits?.length === 9
      }),
    deliveryAddressStreetLine1: Yup.string().when('deliverySameAsPrimary', {
      is: false,
      then: Yup.string().required('Required'),
    }),
    deliveryAddressCity: Yup.string().when('deliverySameAsPrimary', {
      is: false,
      then: Yup.string().required('Required'),
    }),
    deliveryAddressState: Yup.string().when('deliverySameAsPrimary', {
      is: false,
      then: Yup.string().required('Required'),
    }),
    deliveryAddressZip: Yup.string().when('deliverySameAsPrimary', {
      is: false,
      then: Yup.string().required('Required'),
    }),
    deliverySameAsPrimary: Yup.boolean().required('Required'),
    heightFt: Yup.number().min(1, 'Must be greater than 0').max(8, 'Must be less than 8'),
    heightIn: Yup.number().min(0, 'Must be greater than 0').max(11, 'Must be less than 11'),
    weight: Yup.number().min(1, 'Must be greater than 0').max(1000, 'Must be less than 1000'),
  })

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={userValidation}
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          const formatedValues = formatUserPayload(values)
          if (labUser) {
            // we need to be able to update patient of the order (vs update patient)
            if (orderId) {
              await updateLabOrderPatient({ id, ...formatedValues, orderId }).unwrap()
            } else {
              await updateLabPatient({ id, ...formatedValues }).unwrap()
            }
          } else {
            await updatePatient({ id, ...formatedValues }).unwrap()
          }

          if (window.location.href.indexOf('orderEdit') > 0 && !!route) {
            navigate(route)
          }
          Toast({ type: 'success', label: `Patient updated!` })
          postSubmitAction()
        } catch (err) {
          Toast({ type: 'error', label: err.data.message })
        }
      }}>
      {({ values, errors, touched, handleChange, setFieldValue, dirty, isSubmitting }) => (
        <Form id="patientUpsert">
          <FormikDirtyStateSync isDirty={dirty} setFormDirty={setFormDirty} />
          <fieldset disabled={locked}>
            <PatientForm
              context={labUser ? 'LAB' : 'DME'}
              route={route}
              handleView={handleView}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              initialValues={initialValues}
              isNewOrder={isNewOrder}
              modalView={modalView}
              actionButtons={actionButtons}
              promptOnNavigation={!labUser && dirty && !isSubmitting}
              order={order}
              disabledValues={
                !unlockedForm
                  ? {
                      firstName: true,
                      lastName: true,
                      middleName: true,
                      suffix: true,
                      dob: true,
                      gender: true,
                    }
                  : {}
              }
            />
          </fieldset>
        </Form>
      )}
    </Formik>
  )
}

export default PatientUpsert
