/** @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import moment from 'moment'
import ReactSelect from 'react-select'
import { pick, startCase } from 'lodash'

import { useGetLabPhysicianListingQuery } from 'store/services/labPhysician'
import DataTable from 'components/atoms/DataTable'
import { TableWrapper } from 'components/atoms/TableWrapper'
import DashboardContent from '../atoms/DashboardContent'
import { handleChange } from 'components/atoms/Upsert'
import Input from 'components/atoms/Input'
import Link from 'components/atoms/Link'
import routes from '../constants/routes'
import { PopoverWrapper } from 'components/atoms/PopoverWrapper'
import { keyBy } from 'lodash'
import { useGetLabOfficesQuery } from 'store/services/office'
import Select from 'components/atoms/Select'

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`
const Physicians = () => {
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)
  const [sortOptions, setSortOptions] = useState({})
  const [values, setValues] = useState({
    column: '',
    firstName: '',
    lastName: '',
    npi: '',
  })

  const { data: physicians, isLoading } = useGetLabPhysicianListingQuery({
    ...pick(values, ['firstName', 'lastName', 'npi']),
    page: currentPage,
    size: pageSize,
    sortField: sortOptions?.id,
    sortOrder: sortOptions?.desc ? 'desc' : 'asc',
  })

  const header = [
    {
      accessor: 'npi',
      to: `${routes.index}${routes.physician.root}${routes.physician.upsert}`,
      Header: 'NPI',
    },
    {
      accessor: 'name',
      Header: 'Name',
      Cell: ({ row }) => (
        <div css={{ position: 'relative' }}>
          <div>
            {row.original.lastName}, {row.original.firstName} {row.original.middleName}{' '}
            {row.original.credential}
            <Link
              className="open-new-tab"
              css={{ display: 'none', position: 'absolute', right: '0', top: 0 }}
              to={`${routes.index}${routes.physician.root}${routes.physician.upsert}${row.original.npi}`}
              target="_blank"
              icon="fas fa-external-link-alt"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      ),
    },
    {
      accessor: 'offices',
      Header: 'Offices',
      Cell: ({ row }) => (
        <div>
          {row.original.offices.map((office, index) => (
            <div key={index}>{office.officeName}</div>
          ))}
        </div>
      ),
    },
    {
      accessor: 'addresses',
      Header: 'Addresses',
      Cell: ({ row }) => (
        <div>
          {row.original.addresses.map((address, index) => (
            <div key={index}>
              {address.addressStreetLine1} {address.addressStreetLine2}, {address.addressCity},{' '}
              {address.addressState} {address.addressZip}
            </div>
          ))}
        </div>
      ),
    },
  ]

  return (
    <DashboardContent
      title="Physicians"
      icon="fa fa-hdd"
      content={
        <div className="card card-primary">
          <form onSubmit={() => {}}>
            <div
              className="card-header row"
              css={{ borderBottom: 'none', position: 'relative', zIndex: '999' }}>
              <div className="col-sm-1">
                <Select
                  name="pageSize"
                  className="basic-single w-100 mr-3 form-control"
                  handleChange={(e) => setPageSize(Number(e.target.value))}
                  options={['20', '50', '100'].map((i) => ({ value: i, label: i }))}
                  value={pageSize}
                />
              </div>
              <div className="col input-group mb-3">
                <Input
                  autoFocus={true}
                  value={values.firstName}
                  label={false}
                  placeholder="First Name"
                  name="firstName"
                  parentDiv={null}
                  handleChange={(event) => {
                    handleChange(event, values, setValues)
                  }}
                />{' '}
              </div>
              <div className="col input-group mb-3">
                <Input
                  autoFocus={true}
                  value={values.lastName}
                  label={false}
                  placeholder="Last Name"
                  name="lastName"
                  parentDiv={null}
                  handleChange={(event) => {
                    handleChange(event, values, setValues)
                  }}
                />{' '}
              </div>
              <div className="col input-group mb-3">
                <Input
                  autoFocus={true}
                  value={values.npi}
                  label={false}
                  placeholder="NPI"
                  name="npi"
                  parentDiv={null}
                  handleChange={(event) => {
                    handleChange(event, values, setValues)
                  }}
                />{' '}
              </div>

              <div className="col text-right"></div>
            </div>
            <StyledTableWrapper
              className="card-body row"
              css={{
                '& [role=row]:hover': {
                  '& .open-new-tab': {
                    display: 'block',
                  },
                },
              }}>
              <DataTable
                tableKey={'lab_physicians'}
                data={physicians}
                status={isLoading}
                tableColumns={header}
                pageSize={pageSize}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setSortOptions={setSortOptions}
                entityName="physician"
                onRowClick={(row) => {
                  navigate(
                    `${routes.index}${routes.physician.root}${routes.physician.upsert}${row.original.npi}`,
                  )
                }}
              />
            </StyledTableWrapper>
          </form>
        </div>
      }
      breadcrumb={[{ label: 'Physicians' }]}
    />
  )
}

export default Physicians
