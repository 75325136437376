import { useMemo } from 'react'

import { Order } from 'store/types'

const warn = '#ff110030'
const patientFormCss = (patient: any, handler: 'LAMBDA' | 'SYNTHPOP') =>
  patient && handler !== 'LAMBDA'
    ? {
        'input[name="firstName"]': {
          backgroundColor: patient?.firstName ? 'none' : warn,
        },
        'input[name="middleName"]': {
          backgroundColor: patient?.middleName ? 'none' : warn,
        },
        'input[name="lastName"]': {
          backgroundColor: patient?.lastName ? 'none' : warn,
        },
        'input[name="dob"]': {
          backgroundColor: patient?.dob ? 'none' : warn,
        },
        'select[name="gender"]': {
          backgroundColor: patient?.gender ? 'none' : warn,
        },
        'input[name="homePhone"]': {
          backgroundColor: patient?.homePhone ? 'none' : warn,
        },
        'input[id="address"]': {
          backgroundColor: patient?.address?.data?.line1 ? 'none' : warn,
        },
        'input[name="primaryAddressCity"]': {
          backgroundColor: patient?.address?.data?.city ? 'none' : warn,
        },
        'select[name="primaryAddressState"]': {
          backgroundColor: patient?.address?.data?.state ? 'none' : warn,
        },
        'input[name="primaryAddressZip"]': {
          backgroundColor: patient?.address?.data?.zip ? 'none' : warn,
        },
      }
    : {}

export const useRecognitionState = (order?: Order) => {
  const { rxRecognitionData } = order || {}
  const rxData = rxRecognitionData?.[0]?.data?.data
  const handler = rxRecognitionData?.[0]?.handler
  const rxPhysicianData = rxData?.clinical_details
  const rxPatientData = rxData?.demographics
  const rxContactData = rxData?.contact
  const rxPhysicianResult = rxRecognitionData?.[0]?.verificationResult?.physician?.data
  const rxPatientResult = rxRecognitionData?.[0]?.verificationResult?.patient?.data

  const patientFormMarkup = useMemo(() => patientFormCss(rxPatientResult, handler), [order])

  return { rxPhysicianData, rxPhysicianResult, patientFormMarkup, rxPatientData, rxContactData }
}
