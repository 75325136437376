import { useState } from 'react'
import { useParams } from 'react-router-dom'

import DobUpsert from '../verifyContract/DobUpsert'
import Loading from 'components/atoms/Loading'
import * as api from 'helpers/api'
import { tsOfficePhone } from 'constants/misc'

interface IProps {
  nextStep: () => void
  getOrder: (id: number) => void
}

const Verify = ({ nextStep, getOrder }: IProps) => {
  const { orderId, nonce } = useParams()
  const [error, setError] = useState('')

  const verifyDob = async (dob: string) => {
    try {
      setError('')
      const result = await api.get(`/patient/auth`, { dob, nonce, orderId })
      if (result?.access_token) {
        localStorage.setItem('access_token', result.access_token)
        await getOrder(Number(orderId))
        nextStep()
      }
    } catch (err) {
      if (err.response.status >= 400 && err.response.status < 500) {
        setError('Invalid date of birth entered.')
      }
      console.log(err)
    }
  }

  return (
    <div className="container" style={{ maxWidth: 720 }}>
      <div className="mb-3">
        <h2>Home Sleep Test (HST) - Patient Questionnaire and eSignatures</h2>
        <DobUpsert verifyDob={verifyDob} />
        <p className="text-danger">{error}</p>
      </div>
    </div>
  )
}

export default Verify
