/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import * as Yup from 'yup'

import { requiredPhoneValidation } from 'helpers/validate'
import { confirm } from 'components/molecules/Confirmation'
import { formatUserPayload } from 'components/dme/forms/utils/userFormUtils'
import {
  useSubmitToNightOwlMutation,
  useSendNightOwlWelcomeMutation,
  useLabAobSignRequestMutation,
  useRemoveNightOwlHoldMutation,
  useRevertNightOwlMutation,
  useChangeNightOwlFulfillmentMutation,
  useMarkNightOwlDeliveredMutation,
  usePutNightOwlOnHoldMutation,
  useAssignTpdDeviceAndStartShippingMutation,
} from 'store/services/labOrder'
import { useSendLabNoteMutation } from 'store/services/notes'
import Toast from "../../../atoms/Toast";
import { HoldDialog } from './HoldDialog'
import { PatientDataForm } from './PatientDataForm'
import { DeviceLogistics } from './DeviceLogistics'
import { Order } from 'store/types'

const patientValidation = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  dob: Yup.date().required('Required'),
  mobilePhone: requiredPhoneValidation,
  email: Yup.string().required('Required').email('Invalid Email'),
  primaryAddressStreetLine1: Yup.string().required('Required'),
  primaryAddressCity: Yup.string().required('Required'),
  primaryAddressState: Yup.string().required('Required'),
  primaryAddressZip: Yup.string().required('Required'),
  numberOfNight: Yup.number().required('Required'),
})

interface IProps {
  isLoading: boolean
  order: Order
  orderState?: any
}

export const NightOwlFormHandler = ({ isLoading, order, orderState }: IProps) => {
  const navigate = useNavigate()
  const [holdDialogOpen, setHoldDialogOpen] = useState(false)
  const [submitToNightOwl] = useSubmitToNightOwlMutation()
  const [sendNightOwlWelcome] = useSendNightOwlWelcomeMutation()
  const [assignTpdDeviceAndStartShipping] = useAssignTpdDeviceAndStartShippingMutation()
  const [sendAobSignRequest] = useLabAobSignRequestMutation()
  const [removeNightOwlHold] = useRemoveNightOwlHoldMutation()
  const [revertNightOwl] = useRevertNightOwlMutation()
  const [changeFulfillment] = useChangeNightOwlFulfillmentMutation()
  const [markDelivered] = useMarkNightOwlDeliveredMutation()
  const [putNightOwlOnHold] = usePutNightOwlOnHoldMutation()
  const [sendLabNote] = useSendLabNoteMutation()

  const followUpDate = order?.followUpsList?.find((f) => f.namespace === 'TPD')?.date

  const { patient, thirdPartyDevice } = order || {}
  const orderIdNum = Number(order?.id)
  const mode = !!thirdPartyDevice?.esOrderId ? 'POST_SUBMIT' : 'PRE_SUBMIT'

  const initialValues = {
    firstName: patient?.firstName ?? '',
    lastName: patient?.lastName ?? '',
    dob: moment(patient?.dob).format('MM/DD/YYYY') ?? '',
    mobilePhone: patient?.mobilePhone?.replace(/^\+1/, '') ?? '',
    email: patient?.email ?? '',
    address: patient?.primaryAddressStreetLine1 ?? '',
    primaryAddressStreetLine1: patient?.primaryAddressStreetLine1 ?? '',
    primaryAddressStreetLine2: patient?.primaryAddressStreetLine2 ?? '',
    primaryAddressCity: patient?.primaryAddressCity ?? '',
    primaryAddressState: patient?.primaryAddressState ?? '',
    primaryAddressZip: patient?.primaryAddressZip ?? '',
    numberOfNight: order?.tests?.length,
    serialNumber: '',
  }

  const removeHold = () => {
    removeNightOwlHold({ orderId: orderIdNum })
  }
  const changeToTsFulfillment = async () => {
    try {
      if (
        await confirm('', {
          title: 'Are You Sure?',
          description: 'This action will require TS to ship the device to the patient.',
          yesAction: 'Yes, proceed',
        })
      ) {
        changeFulfillment({
          orderId: orderIdNum,
          fulfillment: 'TESTSMARTER',
        })
      }
    } catch {}
  }
  const revertToStandardDevice = async () => {
    try {
      if (
        await confirm('', {
          title: 'Are You Sure?',
          description:
            'This action will revert the order from NightOwl and the DME will need to hand out a standard oximeter.',
          yesAction: 'Yes, proceed',
        })
      ) {
        revertNightOwl({ orderId: orderIdNum })
        navigate(`/lab/orderEdit/${order?.id}`)
      }
    } catch {}
  }

  const markAsDelivered = async () => {
    try {
      if (
        await confirm('', {
          title: 'Are You Sure?',
          description: 'This action will mark the device as delivered to the patient.',
          yesAction: 'Yes, proceed',
        })
      ) {
        markDelivered({ orderId: orderIdNum })
      }
    } catch {}
  }

  const placeOnHold = (followUpDate: string, note?: string) =>
    Promise.all([
      putNightOwlOnHold({
        orderId: orderIdNum,
        followUpDate,
        note,
      }),
      note
        ? sendLabNote({
            orderId: Number(order.id),
            text: note,
            type: 'scheduling',
          }).unwrap()
        : Promise.resolve(),
    ])

  return (
    <div className="card-body row p-3 px-4">
      <HoldDialog
        show={holdDialogOpen}
        onClose={() => setHoldDialogOpen(false)}
        onSubmit={placeOnHold}
      />
      {isLoading ? (
        <></>
      ) : (
        <Formik
          enableReinitialize={true}
          validationSchema={mode === 'PRE_SUBMIT' ? patientValidation : undefined}
          initialValues={initialValues}
          onSubmit={async (values, bag) => {
            const action = document.activeElement?.dataset.flag
            console.log('values: ', values)
            console.log('action', action)

            if (mode === 'PRE_SUBMIT') {
              const formatedValues = formatUserPayload(values)

              try {
                await submitToNightOwl({
                  orderId: orderIdNum,
                  dob: formatedValues.dob,
                  firstName: formatedValues.firstName,
                  lastName: formatedValues.lastName,
                  cellPhone: formatedValues.mobilePhone,
                  email: formatedValues.email,
                  address1: formatedValues.primaryAddressStreetLine1,
                  address2: formatedValues.primaryAddressStreetLine2,
                  city: formatedValues.primaryAddressCity,
                  state: formatedValues.primaryAddressState,
                  zip: formatedValues.primaryAddressZip,
                  numberOfNight: formatedValues.numberOfNight!,
                  fulfillment: order?.thirdPartyDevice?.fulfillment,
                  country: 'US',
                })
                return
              } catch (e) {
                console.log(e)
                return toast.error('Error submitting to NightOwl', e.message)
              }
            }
            if (action === 'sendWelcome') {
              await sendNightOwlWelcome({
                orderId: orderIdNum,
                email: values.email,
                language: 'en',
              })
              toast.success('NightOwl welcome email sent.')
              if (!orderState.isAobVerified && order.testType !== 'HST') {
                await sendAobSignRequest({
                  orderId: orderIdNum,
                  method: 'sms',
                  phone: order?.patient?.mobilePhone,
                })
                return toast.success('AOB SMS request sent to patient.')
              }
            }
            if (action === 'assignDevice') {
              try {
                await assignTpdDeviceAndStartShipping({
                  orderId: orderIdNum,
                  serial: values.serialNumber,
                  selectedBy: 'test',
                }).unwrap()
                Toast({
                  type: 'success',
                  label: 'Shipping started successfully',
                })
              } catch (err) {
                Toast({
                  type: 'error',
                  label: `Error starting shipping: ${err.data.message}`,
                })
              }
            }
          }}>
          {({ values, errors, touched, handleChange, setFieldValue, setFieldTouched }) => (
            <Form id="patientUpsert">
              <div className="row mb-3">
                {order.testType !== 'HST' && (
                  <div className="col-6">
                    {followUpDate ? (
                      <>
                        <h6 className="mb-1">If patient is ready to continue with shipping</h6>
                        <Button variant="primary" onClick={removeHold}>
                          Remove Hold
                        </Button>
                      </>
                    ) : (
                      <>
                        <h6 className="mb-1" css={{ whiteSpace: 'nowrap' }}>
                          If patient is unreachable or needs more time
                        </h6>
                        <Button variant="outline-primary" onClick={() => setHoldDialogOpen(true)}>
                          Place Order on Hold
                        </Button>
                      </>
                    )}
                  </div>
                )}
                {order.testType !== 'HST' && (
                  <div className="col-6">
                    <h6 className="mb-1" css={{ whiteSpace: 'nowrap' }}>
                      If patient cannot proceed with NightOwl testing
                    </h6>
                    <Button
                      variant="outline-primary"
                      onClick={revertToStandardDevice}
                      disabled={!!followUpDate}>
                      Revert to Standard Order (No NightOwl)
                    </Button>
                  </div>
                )}
                {orderState.isTestSmarterNightOwlFulfillment && thirdPartyDevice?.shippedAt && (
                  <div className="col-6 mt-2">
                    <h6 className="mb-1" css={{ whiteSpace: 'nowrap' }}>
                      If device needs to be marked as 'delivered'
                    </h6>
                    <Button
                      variant="outline-primary"
                      onClick={markAsDelivered}
                      disabled={!!followUpDate}>
                      Mark as Delivered
                    </Button>
                  </div>
                )}
                {thirdPartyDevice?.fulfillment === 'TESTSMARTER' && (
                  <div className="col-6 mt-2">
                    <h6>The NightOwl device will be shipped by TestSmarter.</h6>
                  </div>
                )}
                {!orderState.isTestSmarterNightOwlFulfillment && (
                  <div className="col-6 mt-2">
                    {thirdPartyDevice?.fulfillment === 'PATIENT' && (
                      <h6>Patient already has a NightOwl device.</h6>
                    )}
                    {thirdPartyDevice?.fulfillment === 'DME' && (
                      <h6>DME will hand out the NightOwl device.</h6>
                    )}
                    <h6 className="mb-1" css={{ whiteSpace: 'nowrap' }}>
                      If NightOwl needs to be delivered by TestSmarter
                    </h6>
                    <Button
                      variant="outline-primary"
                      onClick={changeToTsFulfillment}
                      disabled={!!followUpDate}>
                      Switch to TS Fulfillment
                    </Button>
                  </div>
                )}
              </div>
              {mode === 'PRE_SUBMIT' && (
                <PatientDataForm
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              )}
              {mode === 'POST_SUBMIT' && (
                <DeviceLogistics
                  order={order}
                  orderState={orderState}
                  values={values}
                  touched={touched}
                  errors={errors}
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}
