/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'

import states from 'constants/states'
import { phoneValidation, requiredPhoneValidation } from 'helpers/validate'
import MaskInput from 'components/atoms/MaskInput'
import InputMask from 'components/atoms/InputMask'
import GoogleAutocomplete from 'components/atoms/GoogleAutocomplete'
import { TextAreaInput } from 'components/atoms/Textarea'
import { phoneNumberMask, zipMask } from 'helpers/masks'
import { getAddressFields } from 'components/dme/forms/utils/userFormUtils'
import * as generalHelper from 'helpers/generalHelper'
import { HstPatientPaperworkDto, Order } from 'store/types'
import { tsOfficePhone } from 'constants/misc'

interface IProps {
  nextStep: () => void
  order: Order
  savePatientPaperwork: (data: Partial<HstPatientPaperworkDto>) => Promise<void>
}

const formValidation = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  dob: Yup.date().required('Required'),
  homePhone: requiredPhoneValidation,
  mobilePhone: phoneValidation,
  primaryAddressStreetLine1: Yup.string().required('Required'),
  primaryAddressCity: Yup.string().required('Required'),
  primaryAddressState: Yup.string().required('Required'),
  primaryAddressZip: Yup.string().required('Required'),
  heightFt: Yup.string().required('Required'),
  heightIn: Yup.string().required('Required'),
  weight: Yup.string().required('Required'),
  smartphone: Yup.string().required('Required'),
})

const PatientDetails = ({ nextStep, order, savePatientPaperwork }: IProps) => {
  const patientDob = order?.patient?.dob ? moment(order.patient.dob).format('MM/DD/YYYY') : ''

  const initialData = useMemo(
    () => ({
      firstName: order?.hst?.paperwork?.data?.patient?.firstName ?? order?.patient?.firstName ?? '',
      lastName: order?.hst?.paperwork?.data?.patient?.lastName ?? order?.patient?.lastName ?? '',
      middleName:
        order?.hst?.paperwork?.data?.patient?.middleName ?? order?.patient?.middleName ?? '',
      dob: order?.hst?.paperwork?.data?.patient?.dob ?? patientDob ?? '',
      homePhone:
        order?.hst?.paperwork?.data?.patient?.homePhone?.replace(/^\+1/, '') ??
        order?.patient?.homePhone.replace(/^\+1/, '') ??
        '',
      mobilePhone:
        order?.hst?.paperwork?.data?.patient?.mobilePhone?.replace(/^\+1/, '') ??
        order?.patient?.mobilePhone?.replace(/^\+1/, '') ??
        '',
      address:
        order?.hst?.paperwork?.data?.patient?.primaryAddressStreetLine1 ??
        order?.patient?.primaryAddressStreetLine1 ??
        '',
      primaryAddressStreetLine1:
        order?.hst?.paperwork?.data?.patient?.primaryAddressStreetLine1 ??
        order?.patient?.primaryAddressStreetLine1 ??
        '',
      primaryAddressCity:
        order?.hst?.paperwork?.data?.patient?.primaryAddressCity ??
        order?.patient?.primaryAddressCity ??
        '',
      primaryAddressState:
        order?.hst?.paperwork?.data?.patient?.primaryAddressState ??
        order?.patient?.primaryAddressState ??
        '',
      primaryAddressZip:
        order?.hst?.paperwork?.data?.patient?.primaryAddressZip ??
        order?.patient?.primaryAddressZip ??
        '',
      heightFt: order?.hst?.paperwork?.data?.patient?.heightFt ?? order?.patient?.heightFt ?? '',
      heightIn: order?.hst?.paperwork?.data?.patient?.heightIn ?? order?.patient?.heightIn ?? '',
      weight: order?.hst?.paperwork?.data?.patient?.weight ?? order?.patient?.weight ?? '',
      smartphone: order?.hst?.paperwork?.data?.patient?.smartphone ?? '',
      comments: '',
    }),
    [order],
  )

  return (
    <div className="container" style={{ maxWidth: 720 }}>
      <div className="mb-3 pb-3">
        <h2>Home Sleep Test (HST) Patient Registration Form</h2>
        <div className="mt-5 mb-5 pt-3 border-top">
          <p>
            Complete the Home Sleep Test Patient Registration Form to the best of your ability. All
            pages must be completed and delivered back to our office before the Home Sleep Test is
            mailed to you. Contact our staff at our office phone number {tsOfficePhone} with any
            questions. The Patient Registration Form can be hand delivered or mailed to our office
            at the address 301 NW Cole Terrace Suite 101, Lake City, FL 32055.
          </p>
        </div>
        <Formik
          enableReinitialize={true}
          validationSchema={formValidation}
          initialValues={initialData}
          onSubmit={async (values) => {
            console.log(values)
            try {
              await savePatientPaperwork({
                orderId: Number(order.id),
                meta: {
                  completedStep: 'patient',
                },
                data: { patient: values },
              })
              nextStep()
            } catch (err) {}
          }}>
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form id="patientDetails">
              <div className={`container`}>
                <div className="row">
                  <div className="col-sm-12 col-lg-3 form-group textGray">
                    <label htmlFor="firstName">
                      First Name
                      <span className="text-danger">*</span>
                    </label>
                    <Field
                      disabled
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="First Name"
                      value={values.firstName}
                      onChange={handleChange}
                      autoComplete="one-time-code"
                    />
                    {touched.firstName && errors.firstName ? (
                      <div className="text-danger">{errors.firstName}</div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-sm-12 col-lg-2 form-group textGray">
                    <label htmlFor="middleName">Middle Name</label>
                    <Field
                      disabled
                      type="text"
                      name="middleName"
                      className="form-control"
                      placeholder="Middle Name"
                      value={values.middleName}
                      onChange={handleChange}
                      autoComplete="one-time-code"
                    />
                  </div>
                  <div className="col-sm-9 col-lg-4 form-group textGray">
                    <label htmlFor="lastName">
                      Last Name
                      <span className="text-danger">*</span>
                    </label>
                    <Field
                      disabled
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder="Last Name"
                      value={values.lastName}
                      onChange={handleChange}
                      autoComplete="one-time-code"
                    />
                    {touched.lastName && errors.lastName ? (
                      <div className="text-danger">{errors.lastName}</div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-sm-12 col-lg-3 form-group textGray">
                    <label htmlFor="dob">
                      Date Of Birth
                      <span className="text-danger">*</span>
                    </label>
                    <InputMask
                      disabled
                      value={values.dob ?? ' '}
                      className="form-control"
                      name="dob"
                      onChange={handleChange}
                      mask="99/99/9999"
                    />
                    {touched.dob && errors.dob ? <div className="text-danger">Required</div> : ''}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-lg-4 form-group textGray">
                    <label htmlFor="height">
                      Height
                      <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <div className="input-group mr-2">
                        <Field
                          type="text"
                          name="heightFt"
                          className="form-control"
                          placeholder="Feet"
                          value={values.heightFt}
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">ft</span>
                        </div>
                      </div>
                      <div className="input-group">
                        <Field
                          type="text"
                          name="heightIn"
                          className="form-control"
                          placeholder="Inches"
                          value={values.heightIn}
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">in</span>
                        </div>
                      </div>
                    </div>
                    {(touched.heightFt && errors.heightFt) ||
                    (touched.heightIn && errors.heightIn) ? (
                      <div className="text-danger">{errors.heightFt || errors.heightIn}</div>
                    ) : null}
                  </div>
                  <div className="col-sm-12 col-lg-4 form-group textGray">
                    <label htmlFor="weight">
                      Weight
                      <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <Field
                        type="text"
                        name="weight"
                        className="form-control"
                        placeholder="Weight"
                        value={values.weight}
                        onChange={handleChange}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">lbs</span>
                      </div>
                    </div>
                    {touched.weight && errors.weight ? (
                      <div className="text-danger">{errors.weight}</div>
                    ) : null}
                  </div>
                  <div className="col-sm-12 col-lg-4 form-group textGray">
                    <label htmlFor="smartphone">
                      Do you have a smartphone?
                      <span className="text-danger">*</span>
                    </label>
                    <Field
                      className="form-control"
                      as="select"
                      name="smartphone"
                      value={values.smartphone}
                      onChange={handleChange}>
                      <option value="">Select...</option>
                      <option value="iphone">iPhone</option>
                      <option value="android">Android</option>
                      <option value="none">No smart phone</option>
                    </Field>
                    {touched.smartphone && errors.smartphone ? (
                      <div className="text-danger">{errors.smartphone}</div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-lg-3 form-group textGray">
                    <label htmlFor="homePhone">
                      Home Number
                      <span className="text-danger">*</span>
                    </label>
                    <MaskInput
                      disabled
                      value={values.homePhone}
                      onChange={handleChange}
                      name="homePhone"
                      mask={phoneNumberMask}
                      placeholder="(###) ###-####"
                      autoComplete="off"
                    />
                    {touched.homePhone && errors.homePhone ? (
                      <div className="text-danger">{errors.homePhone}</div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-sm-12 col-lg-3 form-group textGray">
                    <label htmlFor="mobilePhone">Mobile Number</label>
                    <MaskInput
                      disabled
                      value={values.mobilePhone}
                      onChange={handleChange}
                      name="mobilePhone"
                      mask={phoneNumberMask}
                      placeholder="(###) ###-####"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              <div className={`container`}>
                <div className="row">
                  <div className="col-sm-12 col-lg-6">
                    <div className="row">
                      <div className="col-12 form-group textGray">
                        <label htmlFor="primaryAddressStreetLine1">
                          {generalHelper.t('Primary Address')}
                          <span className="text-danger">*</span>
                        </label>
                        <GoogleAutocomplete
                          id="address"
                          name="address"
                          onInputChanged={(v) => setFieldValue('primaryAddressStreetLine1', v)}
                          onChange={(place) => {
                            const { address, city, state, zip } = getAddressFields(place, 'address')
                            console.log(address, city, state, zip)
                            setFieldValue('primaryAddressStreetLine1', address)
                            setFieldValue('primaryAddressCity', city)
                            setFieldValue('primaryAddressState', state)
                            setFieldValue('primaryAddressZip', zip)
                          }}
                        />
                        {touched.primaryAddressStreetLine1 && errors.primaryAddressStreetLine1 ? (
                          <div className="text-danger">{errors.primaryAddressStreetLine1}</div>
                        ) : null}
                      </div>
                      <div className="col-12 form-group textGray">
                        <Field
                          type="text"
                          name="primaryAddressStreetLine2"
                          className="form-control"
                          placeholder="Suite / Apt"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 form-group textGray">
                        <label htmlFor="primaryAddressCity">
                          City
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          name="primaryAddressCity"
                          className="form-control"
                          placeholder="City"
                        />
                        {touched.primaryAddressCity && errors.primaryAddressCity ? (
                          <div className="text-danger">{errors.primaryAddressCity}</div>
                        ) : null}
                      </div>
                      <div className="col-4 form-group textGray">
                        <label htmlFor="primaryAddressState">
                          State
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          className="form-control"
                          as="select"
                          name="primaryAddressState"
                          placeholder="State">
                          {states &&
                            states.map((s, i) => (
                              <option key={i} value={s.id}>
                                {s.name}
                              </option>
                            ))}
                        </Field>
                        {touched.primaryAddressState && errors.primaryAddressState ? (
                          <div className="text-danger">{errors.primaryAddressState}</div>
                        ) : null}
                      </div>
                      <div className="col-4 form-group textGray">
                        <label htmlFor="primaryAddressZip">
                          Zip Code
                          <span className="text-danger">*</span>
                        </label>
                        <MaskInput
                          value={values.primaryAddressZip}
                          onChange={handleChange}
                          name="primaryAddressZip"
                          mask={zipMask}
                          placeholder="#####-####"
                        />

                        {touched.primaryAddressZip && errors.primaryAddressZip ? (
                          <div className="text-danger">{errors.primaryAddressZip}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`container`}>
                <div className="row">
                  <div className="col-12 form-group textGray">
                    <Field
                      component={TextAreaInput}
                      name="comments"
                      label="Comments / Special Instructions"
                      rows={3}
                    />
                  </div>
                </div>
              </div>
              <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="primary" type="submit" css={{}}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default PatientDetails
