import { baseApi } from './baseApi'
import { Message, AddMessage } from 'store/types'

export const messagesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDmeMessages: builder.query<Message[], { orderId: string }>({
      query: ({ orderId }) => ({ url: `/dme/orders/${orderId}/messages` }),
      providesTags: ['Messages'],
    }),
    getLabMessages: builder.query<Message[], { orderId: string }>({
      query: ({ orderId }) => ({ url: `/lab/orders/${orderId}/messages` }),
      providesTags: ['Messages'],
    }),
    sendDmeMessage: builder.mutation<Message, AddMessage>({
      query: (data) => ({
        method: `POST`,
        url: `/dme/orders/${data.orderId}/messages`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => ['Messages'],
    }),
    sendLabMessage: builder.mutation<Message, AddMessage>({
      query: (data) => ({
        method: `POST`,
        url: `/lab/orders/${data.orderId}/messages`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => ['Messages'],
    }),
    updateMessageReadStatus: builder.mutation<
      Message,
      { uuid: string; readStatus: 'READ' | 'UNREAD' }
    >({
      query: (data) => ({
        method: `PATCH`,
        url: `/messages/${data.uuid}`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => ['Messages', 'OrderCounts'],
    }),
    sendPatientSms: builder.mutation<void, { orderId: number; message: string }>({
      query: ({ orderId, message }) => ({
        method: `POST`,
        url: `/lab/orders/${orderId}/patient-sms`,
        body: { message, orderId },
      }),
    }),
    isPatientOptedOut: builder.query<{ isOptedOut: boolean }, { orderId: number }>({
      query: ({ orderId }) => ({
        url: `/lab/orders/${orderId}/patient-opted-out`,
      })
    })
  }),
})

export const {
  useGetDmeMessagesQuery,
  useGetLabMessagesQuery,
  useSendDmeMessageMutation,
  useSendLabMessageMutation,
  useUpdateMessageReadStatusMutation,
  useSendPatientSmsMutation,
  useIsPatientOptedOutQuery
} = messagesApi
