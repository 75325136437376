/** @jsxImportSource @emotion/react */
import { useRef, useMemo, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'

import { requiredPhoneValidation } from 'helpers/validate'
import { PhysicianForm, PhysicianFormFields } from 'components/organisms/physician/PhysicianForm'
import { KeyVal } from 'store/types'

const physicianValidation = Yup.object().shape({
  fax: requiredPhoneValidation,
  telephone: requiredPhoneValidation,
  addressStreetLine1: Yup.string().required('Required'),
  addressCity: Yup.string().required('Required'),
  addressState: Yup.string().required('Required'),
  addressZip: Yup.string().required('Required'),
})

interface IProps {
  open: boolean
  address: any
  physician: any
  onClose: () => void
  onSubmit: (data: KeyVal) => void
}

export const UpdateAddressDialog = ({ physician, address, open, onClose, onSubmit }: IProps) => {
  const formRef = useRef<FormikProps<any>>(null)

  const initialValues: PhysicianFormFields = useMemo(
    () => ({
      fax: address?.fax?.replace(/^\+1/, '') ?? '',
      telephone: address?.telephone?.replace(/^\+1/, '') ?? '',
      addressStreetLine1: address?.addressStreetLine1 ?? '',
      addressStreetLine2: address?.addressStreetLine2 ?? '',
      addressCity: address?.addressCity ?? '',
      addressState: address?.addressState ?? '',
      addressZip: address?.addressZip ?? '',
    }),
    [address],
  )

  return (
    <Modal centered show={open} onHide={onClose} size="lg">
      <Modal.Header>
        <Modal.Title>UPDATED ADDRESS DATA</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        innerRef={formRef}
        validationSchema={physicianValidation}
        initialValues={initialValues}
        initialTouched={{ fax: true, telephone: true }}
        onSubmit={onSubmit}>
        {({ isValid, values }) => (
          <>
            <Modal.Body>
              <Form>
                <div className="container p-3">
                  <div className="d-flex w-100 mb-3 pb-3 border-bottom">
                    <div className="col-sm-1 pt-2 d-flex align-items-center">
                      <i className="text-default fa fa-user-md" />
                    </div>
                    <div className="col-sm-3 pt-2  d-flex align-items-center">{physician?.npi}</div>
                    <div className="col-sm-5 pt-2  d-flex align-items-center">{`${physician?.firstName} ${physician?.lastName}`}</div>
                  </div>
                  <PhysicianForm />
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => onSubmit(values)}>
                Save
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  )
}
