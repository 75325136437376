/** @jsxImportSource @emotion/react */
import { useEffect, useState, useMemo } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import styled from '@emotion/styled'

import { TableWrapper } from 'components/atoms/TableWrapper'
import Checkbox from 'components/atoms/IndeterminateCheckbox'
import DataTable from 'components/atoms/DataTable'

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.2rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

interface IProps {
  open: boolean
  addresses: any
  onClose: () => void
  onSubmit: (replaceAddressUuid: string) => void
}

export const DeleteDialog = ({ addresses, open, onClose, onSubmit }: IProps) => {
  const [selectedId, setSelectedId] = useState<string>()

  useEffect(() => {
    if (!open) {
      setSelectedId(undefined)
    }
  }, [open])

  const addressesHeader = [
    {
      id: 'select',
      accessor: 'select',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="pl-1 pr-2">
          <Checkbox
            checked={selectedId === row.original.uuid}
            onChange={(e) => setSelectedId(row.original.uuid)}
          />
        </div>
      ),
    },
    {
      accessor: 'address',
      Header: 'Address',
      Cell: ({ row }) => {
        const address = [
          row.original.addressStreetLine1,
          row.original.addressStreetLine2,
          row.original.addressCity,
          row.original.addressState,
          row.original.addressZip,
        ]
          .filter(Boolean) // Remove null/undefined values
          .join(', ') // Join with commas

        return <div>{address || '-'}</div> // Show dash if no address components
      },
    },
    {
      accessor: 'office',
      Header: 'Office',
      Cell: ({ row }) => <div>{row.original.officeId}</div>,
    },
    {
      accessor: 'phone',
      Header: 'Phone',
      Cell: ({ row }) => <div>{row.original.telephone}</div>,
    },
    {
      accessor: 'fax',
      Header: 'Fax',
      Cell: ({ row }) => <div>{row.original.fax}</div>,
    },
  ]

  return (
    <Modal centered show={open} onHide={onClose} size="lg">
      <Modal.Header>
        <Modal.Title>CONFIRM AND SELECT REPLACEMENT</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row p-3" css={{ width: '100%' }}>
          <h4 className="ml-2">Addresses</h4>
          <div className="col-sm-12">
            <StyledTableWrapper
              className="card-body row"
              css={{
                '& [role=row]:hover': {
                  '& .open-new-tab': {
                    display: 'block',
                  },
                },
              }}>
              <DataTable
                tableKey={'lab_physician_addresses'}
                data={{ items: addresses }}
                tableColumns={addressesHeader}
                pageSize={100}
                setPageSize={() => {}}
                setCurrentPage={() => {}}
                currentPage={0}
                setSortOptions={() => {}}
                entityName="addresses"
                onRowClick={(row) => setSelectedId(row.original.uuid)}
              />
            </StyledTableWrapper>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!selectedId} variant="primary" onClick={() => onSubmit(selectedId!)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
