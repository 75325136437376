/** @jsxImportSource @emotion/react */
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Field } from 'formik'
import moment from 'moment/moment'
import BsButton from 'react-bootstrap/Button'
import { useParams } from 'react-router-dom'

import { MobileInviteDated } from 'ts-back-end/src/business/order/dto/mobile-invite'
import {
  useAssignTpdDeviceAndStartShippingMutation,
  useCreateMobileInviteMutation,
  useInitiateManualShippingMutation,
  useLocalPickupMutation,
  useValidateShippingAddressMutation,
} from 'store/services/labOrder'
import { Order } from 'store/types'
import { CheckboxInput } from '../../../atoms/Checkbox'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined'
import { MaskedTextInput } from '../../../atoms/InputMask'
import Toast from '../../../atoms/Toast'
import { ShippingMethod } from './ShippingMethod'

interface IProps {
  welcomeMessage: MobileInviteDated | undefined
  values: any
  testType: string
  order: Order
}

const SendWelcome = ({ welcomeMessage, values, order, testType }: IProps) => {
  const { orderId } = useParams()
  const [createMobileInvite] = useCreateMobileInviteMutation()
  const [validateShippingAddress] = useValidateShippingAddressMutation()
  const [assignTpdDeviceAndStartShipping] = useAssignTpdDeviceAndStartShippingMutation()
  const [localPickup] = useLocalPickupMutation()
  const [initiateManualShipping] = useInitiateManualShippingMutation()

  const sendWelcome = () => {
    if (!values.sendEmail && !values.sendSms) {
      return createMobileInvite({
        orderId: Number(orderId),
        method: 'none',
      }).then(() => {
        Toast({ type: 'success', label: 'New app code created without notification.' })
      })
    }
    createMobileInvite({
      orderId: Number(orderId),
      method: values.sendEmail && values.sendSms ? 'both' : values.sendEmail ? 'email' : 'sms',
      email: values.email,
      phone: values.mobilePhone,
    }).then(() => {
      Toast({ type: 'success', label: 'Welcome email has been sent to patient' })
    })
  }

  return welcomeMessage ? (
    <div className="d-flex flex-column">
      <div className="mt-4 d-flex">
        <CheckBoxOutlinedIcon className={'mr-2 text-success'} />
        <h5 className={'font-weight-600'}>Welcome email has been sent to patient</h5>
      </div>
      <span>
        {moment(welcomeMessage?.timestamp).format('lll')}: Sent to {values.email}
        {welcomeMessage?.code && (
          <>
            , Activation Code: <span className={'text-bold'}>{welcomeMessage.code}</span>
          </>
        )}
      </span>
      <BsButton
        className={'mt-3'}
        variant={'outline-primary'}
        css={{
          width: 230,
        }}
        onClick={() => {
          createMobileInvite({
            orderId: Number(orderId),
            method: values.email && values.mobilePhone ? 'both' : values.email ? 'email' : 'sms',
            email: values.email || undefined,
            phone: values.mobilePhone || undefined,
          }).then(() => {
            Toast({ type: 'success', label: 'Welcome email has been re-sent to patient' })
          })
        }}>
        <ReplayOutlinedIcon /> Re-Send Welcome Email & SMS
      </BsButton>
    </div>
  ) : (
    <div css={{ width: 600 }}>
      <div className="mt-4 d-flex">
        <ErrorOutlineIcon className={'mr-2 text-danger'} />
        <h5 className={'font-weight-600'}>
          Send welcome email with testing instructions to patient
        </h5>
      </div>
      <span>
        An invitation to use the TestSmarter Patient App will be sent to the patient via email or
        text message.
      </span>
      <div
        className={'mt-4'}
        css={{
          width: 500,
        }}>
        <Field id="sendEmail" name="sendEmail" component={CheckboxInput} label="Send via E-mail" />
        {values.sendEmail && (
          <>
            <label htmlFor="email" className={'font-weight-normal mt-2'} css={{ color: '#888888' }}>
              Confirm Email Address
            </label>
            <Field
              type="text"
              name="email"
              className="form-control"
              placeholder="email@domain.com"
              value={values.email}
              autoComplete="off"
            />
          </>
        )}
      </div>
      <div
        className={'mt-4'}
        css={{
          width: 500,
          marginBottom: 10,
        }}>
        <Field
          id="sendSms"
          name="sendSms"
          component={CheckboxInput}
          label="Send via text-message"
        />
        {values.sendSms && (
          <>
            <label
              htmlFor="mobilePhone"
              className={'font-weight-normal mt-2'}
              css={{ color: '#888888' }}>
              Confirm Mobile Phone
            </label>
            <Field
              component={MaskedTextInput}
              value={values.mobilePhone}
              name="mobilePhone"
              mask="(999) 999-9999"
              autoComplete="new-password"
            />
          </>
        )}
      </div>
      {testType === 'HST' && (
        <ShippingMethod
          testType={testType}
          values={values}
          onShipping={async (setSubmitted) => {
            const validation = await validateShippingAddress({
              orderId: Number(orderId),
            })

            if (!validation.data?.valid) {
              Toast({
                type: 'error',
                label:
                  'The provided shipping address is invalid. Please verify the address and try again.',
              })
              return
            }

            sendWelcome()

            try {
              await assignTpdDeviceAndStartShipping({
                orderId: Number(orderId),
                selectedBy: 'test',
              }).unwrap()
              Toast({
                type: 'success',
                label: 'Shipping started successfully',
              })
            } catch (err) {
              setSubmitted(false)
              Toast({
                type: 'error',
                label: `Error starting shipping: ${err.data.message}`,
              })
            }
          }}
          onManualShipping={async () => {
            sendWelcome()
            await initiateManualShipping({
              orderId: Number(orderId),
              trackingNumber: values.trackingNumber,
              returnTrackingNumber: values.returnTrackingNumber,
            })
          }}
          onLocalPickup={async () => {
            sendWelcome()
            await localPickup({
              orderId: Number(orderId),
            })
          }}
        />
      )}
      {testType !== 'HST' && (
        <BsButton
          className={'mt-4'}
          css={{
            width: 200,
          }}
          onClick={() => {
            sendWelcome()
          }}>
          Send
        </BsButton>
      )}
    </div>
  )
}

export default SendWelcome
