/** @jsxImportSource @emotion/react */

import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLazyGetPatientOrderQuery } from 'store/services/patientEndpoints'
import Spinner from 'react-bootstrap/Spinner'

const PaymentSuccess = () => {
  const [getOrder, { currentData: order, isLoading }] = useLazyGetPatientOrderQuery()
  const { orderId } = useParams()

  useEffect(() => {
    if (orderId && localStorage.getItem('access_token')) {
      getOrder()
    }
  }, [orderId, getOrder])

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '200px' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }

  return (
    <div className="payment-success">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="payment-success__content text-center">
              <div className="d-flex align-items-center justify-content-center">
                <i className="fa fa-check text-success mr-3" css={{ fontSize: 30 }} />
                <h1>Payment Successful</h1>
              </div>
              <div>
                <p css={{ fontSize: 18 }}>
                  {order?.testType === 'HST' ? (
                    <>
                      Thank you for completing your paperwork. You will receive a text message once
                      your device has shipped.
                    </>
                  ) : (
                    'Thank you, we have received your payment. You will receive an email with next steps shortly.'
                  )}
                </p>
              </div>
              <div className="payment-success__content__button">
                <button className="btn btn-primary" onClick={() => window.close()}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccess
