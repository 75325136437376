/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { TableWrapper } from 'components/atoms/TableWrapper'
import DashboardContent from '../atoms/DashboardContent'
import Button from 'components/atoms/Button'
import routes from '../constants/routes'
import Link from 'components/atoms/Link'
import Checkbox from 'components/atoms/IndeterminateCheckbox'
import { confirm } from 'components/molecules/Confirmation'
import ReportPreferenceOptions from 'components/molecules/ReportPreferenceOptions'
import {
  useGetLabPhysicianQuery,
  useUpdateLabPhysicianSettingsMutation,
  useUpdateLabPhysicianAddressesMutation,
  useGetLabPhysicianPatientsQuery,
  useDeleteLabPhysicianMutation,
} from 'store/services/labPhysician'
import DataTable from 'components/atoms/DataTable'
import { DeleteDialog } from './DeleteDialog'
import { UpdateAddressDialog } from './UpdateAddressDialog'
import { KeyVal } from 'store/types'

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.2rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const PhysicianDetails = () => {
  const { npi = '' } = useParams()
  const navigate = useNavigate()
  const [formDirty, setFormDirty] = useState(false)
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)
  const [sortOptions, setSortOptions] = useState({})
  const [pageSizeAddresses, setPageSizeAddresses] = useState(20)
  const [currentPageAddresses, setCurrentPageAddresses] = useState(0)
  const [sortOptionsAddresses, setSortOptionsAddresses] = useState({})
  const { data: physician, isLoading, refetch } = useGetLabPhysicianQuery({ npi })
  const [updateLabPhysicianSettings] = useUpdateLabPhysicianSettingsMutation()
  const [updateLabPhysicianAddresses] = useUpdateLabPhysicianAddressesMutation()
  const [deleteLabPhysician] = useDeleteLabPhysicianMutation()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<string | boolean>(false)
  const [addressDialogOpen, setAddressDialogOpen] = useState<number | boolean>(false)
  const { data: patients, isLoading: isLoadingPatients } = useGetLabPhysicianPatientsQuery({
    npi,
    page: currentPage,
  })

  const officesWithMoreThanOneAddress = useMemo(
    () =>
      physician?.addresses?.filter(
        (address) =>
          physician?.addresses &&
          physician?.addresses.filter((a) => a.officeId === address.officeId).length > 1,
      ),
    [physician],
  )

  const addressesHeader = [
    {
      id: 'select',
      accessor: 'select',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="pl-1 pr-2">
          <Checkbox
            checked={selectedIds.includes(row.original.uuid)}
            onChange={(e) =>
              setSelectedIds((prev) => {
                const uuid = row.original.uuid
                if (prev.includes(uuid)) {
                  return prev?.filter((id) => id !== uuid)
                }
                return [...prev, uuid]
              })
            }
          />
        </div>
      ),
    },
    {
      accessor: 'address',
      Header: 'Address',
      Cell: ({ row }) => {
        const address = [
          row.original.addressStreetLine1,
          row.original.addressStreetLine2,
          row.original.addressCity,
          row.original.addressState,
          row.original.addressZip,
        ]
          .filter(Boolean) // Remove null/undefined values
          .join(', ') // Join with commas

        return <div>{address || '-'}</div> // Show dash if no address components
      },
    },
    {
      accessor: 'office',
      Header: 'Office',
      Cell: ({ row }) => <div>{row.original.officeId}</div>,
    },
    {
      accessor: 'phone',
      Header: 'Phone',
      Cell: ({ row }) => <div>{row.original.telephone}</div>,
    },
    {
      accessor: 'fax',
      Header: 'Fax',
      Cell: ({ row }) => <div>{row.original.fax}</div>,
    },
    {
      id: 'actions',
      Header: '',
      Cell: ({ row }) =>
        officesWithMoreThanOneAddress?.find((a) => a.officeId === row.original.officeId) ? (
          <button
            onClick={() => setDeleteDialogOpen(row.original.uuid)}
            className="btn btn-danger btn-sm"
            aria-label="Delete physician">
            Delete
          </button>
        ) : null,
    },
  ]

  const header = [
    {
      accessor: 'ID',
      Cell: ({ row }) => (
        <Link to={`${routes.index}${routes.patient.root}/${row.original.id}`}>
          {row.original.id}
        </Link>
      ),
      Header: 'ID',
    },
    {
      accessor: 'name',
      Header: 'Name',
      Cell: ({ row }) => (
        <div css={{ position: 'relative' }}>
          <div>
            {row.original.lastName}, {row.original.firstName} {row.original.middleName}{' '}
            <Link
              className="open-new-tab"
              css={{ display: 'none', position: 'absolute', right: '0', top: 0 }}
              to={`${routes.index}${routes.physician.root}${routes.physician.upsert}${row.original.npi}`}
              target="_blank"
              icon="fas fa-external-link-alt"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      ),
    },
    {
      accessor: 'office',
      Header: 'Office',
      Cell: ({ row }) => <div>{row.original.officeName}</div>,
    },
  ]

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }

  const closeAddressDialog = () => {
    setAddressDialogOpen(false)
  }

  const handleDeletePhysician = async (replaceAddressUuid: string) => {
    confirm('physician address')
      .then(async () => {
        if (!physician?.id) return
        const physicianId = physician.addresses?.find(
          (a) => a.uuid === deleteDialogOpen,
        )?.physicianId
        try {
          await deleteLabPhysician({
            physicianId,
            addressUuid: deleteDialogOpen,
            replaceAddressUuid,
          })
          closeDeleteDialog()
          refetch()
        } catch (error) {
          console.error('Error deleting physician:', error)
        }
      })
      .catch(() => {})
  }

  return (
    <DashboardContent
      title="Physician Details"
      icon="fa fa-user-md"
      content={
        <>
          <DeleteDialog
            addresses={physician?.addresses?.filter(
              (a) =>
                a.uuid !== deleteDialogOpen &&
                a.officeId ===
                  physician?.addresses?.find((a) => a.uuid === deleteDialogOpen)?.officeId,
            )}
            open={!!deleteDialogOpen}
            onClose={closeDeleteDialog}
            onSubmit={handleDeletePhysician}
          />
          <UpdateAddressDialog
            address={physician?.addresses?.find((a) => a.uuid !== deleteDialogOpen)}
            physician={physician}
            open={!!addressDialogOpen}
            onClose={closeAddressDialog}
            onSubmit={(values: KeyVal) =>
              updateLabPhysicianAddresses({
                npi,
                addresses: {
                  addressUuids: selectedIds,
                  ...values,
                },
              }).then(() => {
                closeAddressDialog()
                refetch()
                setSelectedIds([])
              })
            }
          />
          <div className="card card-primary mt-3">
            <div className="row p-3">
              <div className="container-fluid">
                {physician && (
                  <div className="row">
                    <div className="col-sm-12 col-md-6 d-flex justify-content-end">
                      <div
                        className="row border rounded p-2 justify-content-between"
                        css={{ flex: 1 }}>
                        <div className="col-1 d-flex align-items-center ml-3">
                          {physician.id ? (
                            <i className="text-success fa fa-user-check" />
                          ) : (
                            <i className="text-default fa fa-user-md" />
                          )}
                        </div>
                        <div className="col d-flex align-items-center">{physician.npi}</div>
                        <div className="col d-flex align-items-center">
                          {physician.firstName} {physician.lastName}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 d-flex justify-content-end">
                      <ReportPreferenceOptions
                        value={physician.settings?.[0].settings}
                        onChange={(settings) =>
                          updateLabPhysicianSettings({
                            npi: physician.npi,
                            settings,
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="row p-3" css={{ width: '100%' }}>
                <h4 className="ml-2">Addresses</h4>
                <div className="col-sm-12">
                  <StyledTableWrapper
                    className="card-body row"
                    css={{
                      '& [role=row]:hover': {
                        '& .open-new-tab': {
                          display: 'block',
                        },
                      },
                    }}>
                    <DataTable
                      tableKey={'lab_physician_addresses'}
                      data={{ items: physician?.addresses }}
                      status={isLoading}
                      tableColumns={addressesHeader}
                      pageSize={pageSizeAddresses}
                      setPageSize={setPageSizeAddresses}
                      setCurrentPage={setCurrentPageAddresses}
                      currentPage={currentPageAddresses}
                      setSortOptions={setSortOptionsAddresses}
                      entityName="addresses"
                      onRowClick={(row) => {}}
                    />
                  </StyledTableWrapper>
                </div>
              </div>
              <div className="col-sm-12 d-flex justify-content-start">
                <Button
                  className="btn btn-primary"
                  label="Update Addresses"
                  disabled={!selectedIds.length}
                  onClick={() => setAddressDialogOpen(selectedIds[0])}
                />
              </div>
            </div>
          </div>
          <div className="card card-primary mt-3">
            <div className="row p-3">
              <h4 className="ml-2">Patients</h4>
              <div className="col-sm-12">
                <StyledTableWrapper
                  className="card-body row"
                  css={{
                    '& [role=row]:hover': {
                      '& .open-new-tab': {
                        display: 'block',
                      },
                    },
                  }}>
                  <DataTable
                    select
                    tableKey={'lab_physician_patients'}
                    data={patients}
                    status={isLoadingPatients}
                    tableColumns={header}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    setSortOptions={setSortOptions}
                    entityName="physician"
                    onRowClick={(row) => {
                      navigate(`${routes.index}${routes.patient.root}/${row.original.id}`)
                    }}
                  />
                </StyledTableWrapper>
              </div>
            </div>
          </div>
        </>
      }
      breadcrumb={[
        {
          label: 'Physicians',
          to: `${routes.index}${routes.physician.root}`,
        },
        {
          label: `${physician?.lastName}, ${physician?.firstName} ${physician?.credential || ''}`,
        },
      ]}
    />
  )
}

export default PhysicianDetails
