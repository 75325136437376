/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { debounce } from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'
import { Formik } from 'formik'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import DashboardContent from '../atoms/DashboardContent'
import Button from '../../atoms/Button'
import routes from '../constants/routes'
import TitleLead from '../../atoms/TitleLead'
import * as generalHelper from '../../../helpers/generalHelper'
import { useGetPatientsQuery } from 'store/services/patient'
import { patientNewSaved } from 'store/slice/patientSlice'
import NewPatientForm, { FormValues } from './NewPatientForm'
import NewPatientResults from './NewPatientResults'

const dobRegex = /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/
const SEARCH_DELAY = 800

interface SearchFilter {
  firstName?: string
  lastName?: string
  dob?: string
}

const patientValidation = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  dob: Yup.string()
    .required('Required')
    .test(
      'dob-check',
      'A valid date needs to be entered',
      (val) => dobRegex.test(val) && moment(val).isValid(),
    ),
  gender: Yup.string().required('Required'),
})

const PatientNewScreen = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [formValues, setFormValues] = useState<FormValues>()
  const [isValid, setIsValid] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [submitCount, setSubmitCount] = useState(0)
  const [searchFilters, setSearchFilters] = useState<SearchFilter>()
  const currentOfficeId = useAppSelector((state) => state.init.currentOfficeId)

  const { data: patients, isFetching } = useGetPatientsQuery({
    ...(searchFilters?.firstName && { first_name: searchFilters?.firstName }),
    ...(searchFilters?.lastName && { last_name: searchFilters?.lastName }),
    ...(searchFilters?.dob &&
      searchFilters.dob.length > 7 && {
        dob: moment(searchFilters?.dob).format('YYYY-MM-DD'),
      }),
    office_id: currentOfficeId,
  })

  const initialValues: FormValues = {
    firstName: location?.state?.firstName ?? '',
    middleName: '',
    lastName: location?.state?.lastName ?? '',
    suffix: '',
    dob: location?.state?.dob ?? '',
    gender: '',
  }

  useEffect(() => {
    const searchFn = debounce(() => {
      const dob = !isNaN(Date.parse(formValues?.dob ?? '')) ? formValues?.dob : null
      const filters = {
        firstName: formValues?.firstName,
        lastName: formValues?.lastName,
        ...(dob && { dob }),
      }

      setSearchFilters(filters)
    }, SEARCH_DELAY)

    searchFn()

    return () => {
      searchFn.cancel()
    }
  }, [formValues])

  const onSubmit = (values: FormValues) => {
    values.deliverySameAsPrimary = true
    console.log(values)
    dispatch(patientNewSaved(values))
    navigate(`${routes.index}${routes.order.newOrder}${routes.patient.new}/orderDetails`)
  }

  return (
    <DashboardContent
      title="New Order"
      subtitle="Find Patient"
      content={
        <>
          <div className="card card-primary">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-start">
                  <TitleLead className="ml-2 my-3" label={generalHelper.t('Patient Information')} />
                </div>
              </div>

              {!isValid && submitCount > 0 && (
                <div className="row">
                  <div className="col-sm-12">
                    <div className="alert alert-warning" role="alert">
                      Patient Information is missing. Please review the information below
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-sm-12">
                  <Formik
                    enableReinitialize
                    validateOnBlur
                    validationSchema={patientValidation}
                    initialValues={initialValues}
                    onSubmit={onSubmit}>
                    {({
                      values,
                      errors,
                      touched,
                      dirty,
                      handleBlur,
                      submitCount,
                      isValid,
                      handleChange,
                      handleSubmit,
                    }) => {
                      setFormValues(values)
                      setIsValid(isValid)
                      setIsDirty(dirty)
                      setSubmitCount(submitCount)
                      return (
                        <form id="newPatient" className="px-2" onSubmit={handleSubmit}>
                          <NewPatientForm
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values}
                            errors={errors}
                            touched={touched}
                            submitButton={
                              <Button
                                className="btn btn-danger"
                                css={{ transform: 'translateY(-10px)' }}
                                type="submit"
                                form="newPatient"
                                disabled={!dirty || !isValid}
                                label="Create New Patient"
                              />
                            }
                          />
                        </form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div>
            {isDirty && <NewPatientResults isLoading={isFetching} patients={patients?.items} />}
          </div>
        </>
      }
      titleActionButton={<div />}
      breadcrumb={[
        { label: 'Home', to: `${routes.index}` },
        {
          label: 'New Order',
          to: `${routes.index}${routes.order.newOrder}`,
        },
        {
          label: 'New Patient',
          to: `${routes.index}${routes.order.newOrder}/${routes.patient.new}`,
        },
      ]}
    />
  )
}

export default PatientNewScreen
