const possibleSteps = [
  {
    path: '',
    label: 'Verify Identity',
    devices: ['OS'],
  },
  {
    path: 'patient-details',
    label: 'Patient Details',
    devices: ['OS'],
    completedStep: 'patient',
  },
  {
    path: 'signature-requests',
    label: 'Signature Requests',
    devices: ['OS'],
    completedStep: 'signatureRequests',
  },
  {
    path: 'stripe',
    label: 'Process Payment',
    devices: ['OS'],
    completedStep: 'stripe',
  },
]

export const getSteps = (deviceType: 'OS') =>
  possibleSteps
    .filter((step) => step.devices.includes(deviceType))
    .map((step, idx) => ({ ...step, idx }))
