/** @jsxImportSource @emotion/react */
import {memo} from 'react'
import moment from 'moment'
import Button from 'react-bootstrap/Button'
import styled from '@emotion/styled'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUndo} from '@fortawesome/free-solid-svg-icons'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import Spinner from 'react-bootstrap/Spinner'

import {t} from 'helpers/generalHelper'
import DatePickerComponent from 'components/atoms/DateTimePicker'
import {Order} from 'store/types'
import {DeviceSession} from 'ts-back-end'
import {SessionSelection} from 'components/dme/orderEdit/views/Upload.tab'
import 'react-datepicker/dist/react-datepicker.css'
import {DeviceSessionGroup} from "ts-back-end/src/business/order/dto";

type DeviceSessionWithSeries = DeviceSession & { series: number }

const MIN_NIGHT_DURATION = 2 * 60 * 60

const StyledAlert = styled.div`
    line-height: 1.3rem;
`

const SeriesOverlay = styled.div`
    position: absolute;
    top: 0;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border: 2px solid #2c83bd;
    display: flex;
    align-items: center;
    justify-content: center;

    #shading {
        position: absolute;
        background-color: #2c83bd;
        opacity: 0.2;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #modal {
        background-color: white;
        border-radius: 5px;
        width: 180px;
        height: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 15px 2px #00000020;
    }

    .anomalous {
        padding: 10px;
        width: 210px !important;
        height: 200px !important;
    }
`

const getSeries = (data: DeviceSessionGroup[] = []): DeviceSessionWithSeries[][] => {
  let i = 0;
  return data.map((sessionGroup) => {
    return sessionGroup.sessions.map((session) => {
      return {
        ...session,
        index: i++,
        series: session.groupIndex ?? session.indexWithinSessionGroup,
      }
    })
  })
}

export const formatDuration = (duration: number): string => {
  const parts = []
  const h: number = Math.floor(duration / 3600)
  duration -= h * 3600
  const m: number = Math.floor(duration / 60)
  duration -= m * 60
  const s: number = duration
  if (h) parts.push(h + (h > 1 ? ' hours,' : ' hour,'))
  if (m) parts.push(m + (m > 1 ? ' minutes' : ' minute'))
  if (s) parts.push('and ' + s + (s > 1 ? ' seconds' : ' second'))
  return parts.join(' ')
}

const detectDateAnomalies = (series: DeviceSessionWithSeries[][], orderCreatedAt: Date | string) => {
  if (orderCreatedAt instanceof Date) {
    orderCreatedAt = orderCreatedAt.toISOString();
  }
  const anomalies = {}
  series.forEach((sessions, seriesIdx) => {
    anomalies[seriesIdx] = {}
    sessions.forEach((session, sessionIdx) => {
      let isAnomalous = false
      if (moment(orderCreatedAt).isAfter(session.timeStarted)) {
        // Session was recorded before the order was created
        isAnomalous = true
      }
      if (moment(session.timeStarted).isAfter(moment())) {
        // Session is in the future
        isAnomalous = true
      }
      anomalies[seriesIdx][sessionIdx] = isAnomalous ? 'TEST_DATE_OUT_OF_RANGE' : null
    })
    if (Object.values(anomalies[seriesIdx]).some((i) => i)) {
      anomalies[seriesIdx].isAnomalous = true
    }
  })
  return anomalies
}

interface IProps {
  finalizeUpload: () => void
  loading: boolean
  onChange: (data: SessionSelection) => void
  orderCreatedAt: Date | string
  sessionGroups: Order.sessions.sessionGroups
  sessionSelection: SessionSelection
  sessions: Order.sessions.sessions
  setAnomalies: (data: string[]) => void
  setTimeOverrides: (data: { [key: string]: Date }) => void
  tests: any[]
  timeOverrides: { [key: string]: Date }
}

const SessionsTable = memo(
  ({
     finalizeUpload,
     loading,
     onChange,
     orderCreatedAt,
     sessionGroups,
     sessionSelection,
     sessions,
     setAnomalies,
     setTimeOverrides,
     tests,
     timeOverrides,
   }: IProps) => {
    const nights = tests && tests?.length > 1 ? 2 : 1
    const series = Array.isArray(sessionGroups) ? getSeries(sessionGroups) : []
    const nightConditions = tests?.map((night) => {
      if (night.oxygen === 0) return 'On Room Air'
      if (night.oxygen === 1) return 'On Oxygen'
      return ''
    })
    const canSelect = nights <= series?.length

    const nightValid = sessionGroups.map((sessionGroup) => {
      return sessionGroup.isValid
    });

    const assignedNights = Object.keys(sessionSelection).filter((night) => sessionSelection[night])

    // console.log('*********', sessions, series)

    const anomalies = detectDateAnomalies(series, orderCreatedAt)
    const showAlert = anomalies && Object.values(anomalies).some((i) => i.isAnomalous)

    const onSelect = (seriesIndex: number) => {
      if (!sessionSelection['0']) {
        setAnomalies({0: anomalies[seriesIndex], 1: null})
        return onChange({
          ...sessionSelection,
          0: series[seriesIndex].map((session) => session.index),
        })
      }
      if (!sessionSelection['1'] && nights === 2) {
        setAnomalies((p) => ({...p, 1: anomalies[seriesIndex]}))
        return onChange({
          ...sessionSelection,
          1: series[seriesIndex].map((session) => session.index),
        })
      }
    }

    const onRemove = (nightIndex: number) => {
      const newSelection = {...sessionSelection}
      delete newSelection[nightIndex - 1]
      onChange(newSelection)
      setTimeOverrides((p) => ({...p, [nightIndex - 1]: null}))
    }

    const getNightAssignment = (sessions: DeviceSessionWithSeries[]) => {
      const sessionIdx = sessions[0]?.index ?? -1
      return sessionSelection[0]?.includes(sessionIdx)
        ? 1
        : sessionSelection[1]?.includes(sessionIdx)
          ? 2
          : null
    }

    return (
      <div className="px-2">
        {sessions ? (
          <>
            {showAlert ? (
              <StyledAlert className="alert alert-warning" role="alert">
                {nights === 2 && series.length < 2 ? (
                  `There are not enough sessions to select two nights for this order.  You can change the
                  test conditions to a 1-night order and generate a report or click Choose Oximeter above, checkout an oximeter and once the
                  patient has re-tested, please use the "Upload More Data" button to add the new
                  sessions to this order.`
                ) : (
                  <>
                    We have detected some test sessions with dates that might be wrong. You can
                    provide an optional alternative test date after selecting the night. Then click
                    the <strong>Complete Upload</strong> button to save your changes.
                    {nights === 2 &&
                      series.length === 2 &&
                      '  You must select two nights since this is a two-night order.'}
                  </>
                )}
              </StyledAlert>
            ) : (
              <p className="mb-3">
                {nights === 2 && (
                  <>
                    {series.length === 2 ? (
                      'Please choose which sessions to use for each night. You must select two nights since this is a two-night order.'
                    ) : (
                      <StyledAlert className="alert alert-warning" role="alert">
                        There are not enough sessions to select two nights for this order. Once the
                        patient has re-tested, please use the "Upload More Data" button to add the
                        new sessions to this order.
                      </StyledAlert>
                    )}
                  </>
                )}
                {nights === 1 &&
                  nightValid[0] &&
                  'Please choose which sessions to keep or to discard.'}
              </p>
            )}
            {series.map((sessions, seriesIdx) => {
              const night = getNightAssignment(sessions)
              const hideSelect = !!night || assignedNights.length >= nights
              return (
                <div key={`serie-${seriesIdx}`} className="my-4 position-relative">
                  {night && (
                    <SeriesOverlay>
                      <div id="shading"/>
                      <div
                        id="modal"
                        className={anomalies[seriesIdx].isAnomalous && `anomalous`}
                        css={{zIndex: series.length - seriesIdx}}>
                        <span className="mb-2">
                          <strong>NIGHT {night}</strong>
                        </span>
                        <span style={{fontSize: 14}}>{nightConditions?.[night - 1]}</span>

                        {anomalies[seriesIdx].isAnomalous && (
                          <>
                            <span className="my-2 text-center" style={{fontSize: 14}}>
                              This night is outside of a 90 day window. Select test start time:
                            </span>
                            <DatePickerComponent
                              dateFormat="MM/dd/yyyy hh:mm a"
                              minDate={moment().utc().subtract(90, 'days').toDate()}
                              maxDate={moment().utc().toDate()}
                              value={timeOverrides[night - 1]}
                              timeIntervals={1}
                              handleChange={(date) =>
                                setTimeOverrides((p) => ({...p, [night - 1]: date}))
                              }
                            />
                          </>
                        )}
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => onRemove(night)}
                          css={{transform: 'translateY(10px)', minWidth: 130}}>
                          <FontAwesomeIcon icon={faUndo} css={{marginRight: 10}}/>
                          Deselect
                        </Button>
                        <Button
                          variant="primary"
                          size="sm"
                          disabled={loading}
                          onClick={finalizeUpload}
                          css={{transform: 'translateY(10px)', marginTop: '5px', minWidth: 130}}>
                          <FontAwesomeIcon icon={faCheck} css={{marginRight: 10}}/>
                          Complete Upload
                          {loading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="ml-2"
                            />
                          )}
                        </Button>
                      </div>
                    </SeriesOverlay>
                  )}
                  <div className="d-flex justify-content-between">
                    <strong className="my-2">
                      {moment(sessions[0]?.timeStarted).utc().format('MM/DD/YYYY')}
                    </strong>
                    {nightValid[seriesIdx] ? (
                      <Button
                        variant="primary"
                        disabled={!canSelect}
                        size="sm"
                        onClick={() => onSelect(seriesIdx)}
                        style={{
                          transform: 'translateY(10px)',
                          visibility: hideSelect ? 'hidden' : 'visible',
                        }}>
                        Select This Night
                      </Button>
                    ) : (
                      <Button disabled variant="outline-danger">
                        Not enough data to generate a report
                      </Button>
                    )}
                  </div>
                  <div
                    className="row flex-row flex-nowrap justify-content-between align-items-center mt-1 p-2 mt-2"
                    style={{
                      color: '#6D757E',
                      borderBottom: '1px solid #ced4da',
                    }}>
                    <div className="flex-shrink-0" style={{width: 80}}>
                      {t('Session')}
                    </div>
                    <div style={{width: 520}}>{t('Testing Started')}</div>
                    <div style={{width: 500}}>{t('Testing Stopped')}</div>
                    <div style={{width: '100%'}}>{t('Duration')}</div>
                  </div>
                  {sessions.map((session, sessionIdx) => (
                    <div
                      key={`session-${sessionIdx}`}
                      className="row flex-row flex-nowrap justify-content-between align-items-center my-1 p-2"
                      style={{
                        border: '1px solid #ced4da',
                        borderRadius: 4,
                        ...(!session.isValid && {opacity: 0.5}),
                      }}>
                      <div className="flex-shrink-0" style={{width: 80}}>
                        <div
                          style={{
                            width: 24,
                            textAlign: 'center',
                            border: '1px solid #2C83BD',
                            borderRadius: 4,
                            color: '#2C83BD',
                          }}>
                          {session.index + 1}
                        </div>
                      </div>
                      <div style={{width: 520}}>
                        {moment(session.timeStarted).utc().format('MM/DD/YYYY h:mma')}
                        {anomalies[seriesIdx][sessionIdx] && (
                          <i
                            className="fas fa-exclamation-triangle text-warning"
                            style={{marginLeft: 10}}
                          />
                        )}
                      </div>
                      <div style={{width: 500}}>
                        {moment(session.timeStopped).utc().format('MM/DD/YYYY h:mma')}
                      </div>
                      <div style={{width: '100%'}}>
                        {formatDuration(session.duration)}
                        {!session.isValid && ' (invalid session)'}
                      </div>
                    </div>
                  ))}
                </div>
              )
            })}
          </>
        ) : (
          <p style={{width: '100%', textAlign: 'center'}}>Loading sessions...</p>
        )}
      </div>
    )
  },
)

export default SessionsTable
