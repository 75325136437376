import { baseApi } from './baseApi'
import { Physician } from 'store/types'
import {
  UpdatePhysicianSettingsDto,
  LabUpdatePhysicianAddressesDto,
} from 'ts-back-end/src/business/physician/dto/physician.dto'

type LatestPhysiciansParams = {
  officeId: number | string
  patientId: number | string
  count: number
}

export const physicianApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLabPhysicians: builder.query<Physician[], { [key: string]: string }>({
      query: (query) => ({ url: `/lab/physicians/search`, params: query }),
    }),
    getLabPhysician: builder.query<Physician, { npi: string }>({
      query: (query) => ({ url: `/lab/physicians/${query.npi}` }),
    }),
    getLabPhysicianPatients: builder.query<Physician, { npi: string; page: number }>({
      query: ({ npi, ...query }) => ({
        url: `/lab/physicians/${npi}/patients`,
        params: query,
      }),
    }),
    updateLabPhysicianSettings: builder.mutation<
      Physician,
      { npi: string; settings: UpdatePhysicianSettingsDto }
    >({
      query: (query) => ({
        url: `/lab/physicians/${query.npi}/settings`,
        method: 'PUT',
        body: query.settings,
      }),
    }),
    updateLabPhysicianAddresses: builder.mutation<
      Physician,
      { npi: string; addresses: LabUpdatePhysicianAddressesDto }
    >({
      query: (query) => ({
        url: `/lab/physicians/${query.npi}/addresses`,
        method: 'PUT',
        body: query.addresses,
      }),
    }),
    deleteLabPhysician: builder.mutation<
      Physician,
      { physicianId: number; addressUuid: string; replaceAddressUuid: string }
    >({
      query: ({ physicianId, addressUuid, replaceAddressUuid }) => ({
        url: `/lab/physicians/${physicianId}/addresses/${addressUuid}`,
        method: 'DELETE',
        body: { replaceAddressUuid },
      }),
    }),
    getDmePhysicians: builder.query<Physician[], { [key: string]: string }>({
      query: (query) => ({ url: `/lab/physicians`, params: query }),
    }),
    getLabPhysicianListing: builder.query<Physician[], { [key: string]: string }>({
      query: (query) => ({ url: `/lab/physicians/list`, params: query }),
    }),
    getDmeLatestPhysicians: builder.query<Physician[], LatestPhysiciansParams>({
      query: (query) => ({ url: `/lab/physicians/latest`, params: query }),
    }),
    getPhysicianHistory: builder.query<any, { id: number }>({
      query: (query) => ({ url: `/lab/physicians/${query.id}/history` }),
    }),
    approveAddress: builder.mutation<Physician, { orderId: string; id: string; uuid: string }>({
      query: ({ orderId, id, uuid }) => ({
        method: `PUT`,
        url: `/lab/physicians/${id}/addresses/${uuid}/approval`,
      }),
      invalidatesTags: (result, error, { orderId }) => [{ type: 'Order', id: orderId }],
    }),
    rejectAddress: builder.mutation<Physician, { orderId: string; id: string; uuid: string }>({
      query: ({ orderId, id, uuid }) => ({
        method: `PUT`,
        url: `/lab/physicians/${id}/addresses/${uuid}/rejection`,
      }),
      invalidatesTags: (result, error, { orderId }) => [{ type: 'Order', id: orderId }],
    }),
  }),
})

export const {
  useGetLabPhysiciansQuery,
  useGetPhysicianHistoryQuery,
  useGetDmePhysiciansQuery,
  useGetDmeLatestPhysiciansQuery,
  useApproveAddressMutation,
  useRejectAddressMutation,
  useGetLabPhysicianListingQuery,
  useGetLabPhysicianQuery,
  useGetLabPhysicianPatientsQuery,
  useUpdateLabPhysicianSettingsMutation,
  useUpdateLabPhysicianAddressesMutation,
  useDeleteLabPhysicianMutation,
} = physicianApi
