import { useEffect, useState } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { useGetDmeDevicesQuery } from 'store/services/devices'
import { useGetOrderCountsQuery } from 'store/services/dmeOrder'
import { useLazyGetDmeOfficeTestsQuery } from 'store/services/office'
import { setCurrentOfficeTests } from 'store/slice/initSlice'
import SidebarSection from 'components/atoms/SidebarSection'
import { MenuData } from '../constants/menu'
import { COMPOUND_BUCKET_MAP } from '../constants/orderStatus'

const Menu = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const cachedMenuData = JSON.parse(sessionStorage.getItem('ts-dme-menu') ?? '[]')
  const [menuData, setMenuData] = useState(cachedMenuData?.length ? cachedMenuData : MenuData)
  const [getDmeOffice] = useLazyGetDmeOfficeTestsQuery()
  const init = useAppSelector((state) => state.init)
  const minRoleType = init?.minRoleType ?? {}
  const officeId = useAppSelector((state) => state.init.currentOfficeId)
  const availableTests = useAppSelector((state) => state.init.currentOfficeTests)
  const { data: devices } = useGetDmeDevicesQuery({ officeId })
  const { data: rawOrderCounts, isLoading } = useGetOrderCountsQuery(
    { officeId: Number(officeId) },
    {
      pollingInterval: 180000,
      refetchOnFocus: true,
    },
  )
  // console.log(menuData)
  // console.log(location, searchParams)


  
  useEffect(() => {
    getDmeOffice({ id: Number(init?.currentOfficeId) })
      .unwrap()
      .then((tests) => dispatch(setCurrentOfficeTests(tests)))
  }, [])

  useEffect(() => {
    sessionStorage.setItem('ts-dme-menu', JSON.stringify(menuData))
  }, [menuData])

  useEffect(() => {
    if (availableTests?.POX) {
      createMenuData()
    }
  }, [availableTests])

  const createMenuData = () => {
    const newMenuData = [...MenuData].filter((section) => {
      if (section.menu === 'pulseox' && !availableTests.POX?.available) {
        return false
      }
      if (section.menu === 'capno' && !availableTests.COX?.available) {
        return false
      }
      if (section.menu === 'hst' && !availableTests.HST?.available) {
        return false
      }
      if (section.menu === 'psg' && !availableTests.PSG?.available) {
        return false
      }
      return true
    })
    setMenuData(newMenuData)
  }

  const orderCounts = [...(rawOrderCounts || [])]
  const getCompoundBucketCount = (
    testType: 'POX' | 'COX' | 'HST' | 'PSG',
    bucket: 'ACTIVE_ORDERS' | 'ALL_ORDERS',
  ) => {
    const buckets = COMPOUND_BUCKET_MAP[bucket]
    return rawOrderCounts?.reduce((agg, curr) => {
      if (curr.testType === testType && buckets.includes(curr.bucket)) {
        agg = agg + curr.count
      }
      return agg
    }, 0)
  }

  orderCounts?.push({
    testType: 'POX',
    bucket: 'ALL_ORDERS',
    count: getCompoundBucketCount('POX', 'ALL_ORDERS'),
  })
  orderCounts?.push({
    testType: 'POX',
    bucket: 'ACTIVE_ORDERS',
    count: getCompoundBucketCount('POX', 'ACTIVE_ORDERS'),
  })
  orderCounts?.push({
    testType: 'COX',
    bucket: 'ALL_ORDERS',
    count: getCompoundBucketCount('COX', 'ALL_ORDERS'),
  })
  orderCounts?.push({
    testType: 'COX',
    bucket: 'ACTIVE_ORDERS',
    count: getCompoundBucketCount('COX', 'ACTIVE_ORDERS'),
  })
  orderCounts?.push({
    testType: 'ALL',
    bucket: 'ATTENTION',
    count: rawOrderCounts?.reduce((agg, curr) => {
      if (curr.bucket === 'ATTENTION') {
        agg = agg + curr.count
      }
      return agg
    }, 0),
  })
  orderCounts?.push({
    testType: 'HST',
    bucket: 'ALL_ORDERS',
    count: getCompoundBucketCount('HST', 'ALL_ORDERS'),
  })
  orderCounts?.push({
    testType: 'PSG',
    bucket: 'ALL_ORDERS',
    count: getCompoundBucketCount('PSG', 'ALL_ORDERS'),
  })

  return (
    <aside
      className="main-sidebar sidebar-dark-primary elevation-4 vh-100 vw-50"
      style={{ backgroundColor: '#1D242B' }}>
      <Link to={'/'}>
        <div className="brand-link text-center py-4 border-0">
          <span className="brand-text">
            <img src="/img/logo.png" width="160px" alt="logo" />
          </span>
        </div>
      </Link>
      <div className="sidebar overflow-y-auto overflow-x-hidden vh-80 pb-10">
        <nav>
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false">
            {!isLoading ? (
              <SidebarSection
                location={location}
                searchParams={searchParams}
                data={menuData}
                setMenuData={setMenuData}
                minRole={minRoleType ? minRoleType.id : null}
                badgeData={orderCounts}
                devices={devices}
              />
            ) : (
              <span>{'Loading Order Counts...'}</span>
            )}
          </ul>
        </nav>
        <div className="mb-5">&nbsp;</div>
      </div>
    </aside>
  )
}

export default Menu
