import { Field } from 'formik'

import states from 'constants/states'
import { TextInput } from 'components/atoms/Input'
import { SelectInput } from 'components/atoms/Select'
import { CheckboxInput } from 'components/atoms/Checkbox'
import { MaskedTextInput } from 'components/atoms/InputMask'
import { BILLING_LOCATION_CODES } from 'components/lab/constants/payors'

export interface LabPayerFormValues {
  name: string
  verificationLink: string
  telephone: string
  ourProviderId: string
  addressStreetLine1: string
  addressStreetLine2: string
  addressCity: string
  addressState: string
  addressZip: string
  uPayerId: string
  planId: string
  active: boolean
  inNetwork: boolean
  notes: string
  billingLocationCode: string
  claimFileIndicator?: string
  placeOfService?: string
}

export const LabPayerForm = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 form-group">
          <label className="textGray">Name</label>
          <Field component={TextInput} label={false} placeholder="Payor Name" name="name" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group">
          <label className="textGray">Verification Link</label>
          <Field
            component={TextInput}
            label={false}
            placeholder="Verification Link"
            name="verificationLink"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group">
          <label className="textGray">Telephone Number</label>
          <Field component={MaskedTextInput} name="telephone" mask="(999) 999-9999" />
        </div>
      </div>
      <div className="row">
        <div className="col-4 form-group">
          <label className="textGray">Payor ID</label>
          <Field component={TextInput} name="uPayerId" />
        </div>
        <div className="col-4 form-group">
          <label className="textGray">TS Provider ID</label>
          <Field component={TextInput} name="ourProviderId" />
        </div>
        <div className="col-4 form-group">
          <label className="textGray">CureMD Plan ID</label>
          <Field component={TextInput} name="planId" />
        </div>
        <div className="col-4 form-group">
          <Field
            component={SelectInput}
            options={BILLING_LOCATION_CODES}
            labelClassName="textGray"
            name="billingLocationCode"
            label="Billing Location Code"
          />
        </div>
        <div className="col-4 form-group">
          <label className="textGray">Optum Payer ID</label>
          <Field component={TextInput} name="optumPayerId" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group">
          <label className="textGray">Address</label>
          <Field
            component={TextInput}
            label={false}
            placeholder="Street Address"
            name="addressStreetLine1"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            component={TextInput}
            label={false}
            placeholder="Suite/Apt"
            name="addressStreetLine2"
          />
        </div>
        <div className="col-12"></div>
      </div>
      <div className="row">
        <div className="col-4">
          <label className="textGray">City</label>
          <Field component={TextInput} label={false} placeholder="City" name="addressCity" />
        </div>
        <div className="col-4">
          <Field
            component={SelectInput}
            options={states || []}
            defaultLabel="State"
            labelClassName="textGray"
            name="addressState"
            label="State"
          />
        </div>
        <div className="col-4">
          <label className="textGray">Zip Code</label>
          <Field component={TextInput} label={false} placeholder="Zip Code" name="addressZip" />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 form-group">
          <label className="textGray">Billing Properties</label>
        </div>
        <div className="col-6 form-group">
          <Field
            component={SelectInput}
            noDefault
            options={[
              { key: false, value: 'Default' },
              { key: 'BL', value: 'BL: Blue Cross / Blue Shield' },
              { key: 'CI', value: 'Commercial Insurance' },
              { key: 'DS', value: 'DS: Disability' },
              { key: 'HM', value: 'HM: Health Maintenance Organization (HMO)' },
              { key: 'MA', value: 'MA: Medicare Part A' },
              { key: 'MB', value: 'MB: Medicare Part B' },
              { key: 'MC', value: 'MC: Medicaid' },
              { key: 'CH', value: 'CH: Tricare' },
            ]}
            // defaultLabel="CI: Commercial Insurance"
            labelClassName="textGray"
            name="claimFileIndicator"
            label="Claim Filing Indicator (CFI)"
          />
        </div>
        <div className="col-6 form-group">
          <Field
            component={SelectInput}
            noDefault
            options={[
              { key: false, value: 'Default' },
              { key: '11', value: '11: Office' },
              { key: '12', value: '12: Home' },
              { key: '81', value: '81: Independent Laboratory' },
            ]}
            labelClassName="textGray"
            name="placeOfService"
            label="Place Of Service (POS)"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group">
          <label className="textGray">Notes</label>
          <Field component="textarea" name="notes" placeholder="Notes" className="form-control" />
        </div>
      </div>
      <div className="row">
        <div className="col-6" />
        <div className="col-6 d-flex justify-content-between">
          <Field id="inNetwork" name="inNetwork" component={CheckboxInput} label="In Network" />
          <Field id="active" name="active" component={CheckboxInput} label="Active" />
        </div>
      </div>
    </>
  )
}
