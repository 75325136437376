import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import styled from '@emotion/styled'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AdjustIcon from '@mui/icons-material/Adjust'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#198754',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#198754',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1,
  },
}))

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#198754',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#198754',
    zIndex: 1,
    fontSize: 28,
  },
  '& .QontoStepIcon-circle': {
    fontSize: 28,
  },
}))

function QontoStepIcon(props) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckCircleIcon className="QontoStepIcon-completedIcon" color="primary" />
      ) : active ? (
        <AdjustIcon className="QontoStepIcon-completedIcon" color="primary" />
      ) : (
        <RadioButtonUncheckedIcon className="QontoStepIcon-circle" color="disabled" />
      )}
    </QontoStepIconRoot>
  )
}

interface IProps {
  activeStep: number
  steps: string[]
  sx?: any
}

export const HstPaperworkStepper = ({ activeStep, steps, sx }: IProps) => {
  return (
    <Stepper activeStep={activeStep} connector={<QontoConnector />} alternativeLabel sx={sx}>
      {steps.map((label, idx) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={QontoStepIcon}
            sx={{
              '& .MuiStepLabel-labelContainer': {
                visibility: 'hidden',
              },
              '& .Mui-active': {
                visibility: 'visible !important',
              },
            }}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
