export const forms = [
  {
    type: 'Customer Instruction Handouts',
    name: 'TestSmarter OneTrip',
    model: 'tsot',
    subModel: 'POX',
    keyEn: 'patient-instructions-original/tsot-instructions-en.pdf',
    keyEs: 'patient-instructions-original/tsot-instructions-es.pdf',
  },
  {
    type: 'Customer Instruction Handouts',
    name: 'TestSmarter OneTrip (Pediatric)',
    model: 'tsot',
    keyEn: 'patient-instructions-original/tsot-pediatric-instructions-en.pdf',
    keyEs: '',
  },
  {
    type: 'Customer Instruction Handouts',
    name: 'Respironics 920M Plus or Nonin PalmSAT 2500	',
    model: 'n2500',
    keyEn: 'patient-instructions-original/920m-instructions-en.pdf',
    keyEs: 'patient-instructions-original/920m-instructions-es.pdf',
  },
  {
    type: 'Customer Instruction Handouts',
    name: 'Nonin WristOx 3150	',
    model: 'n3150',
    keyEn: 'patient-instructions-original/n3150-instructions-en.pdf',
    keyEs: 'patient-instructions-original/n3150-instructions-es.pdf',
  },
  {
    type: 'Customer Instruction Handouts',
    name: 'C50F	',
    model: 'c50f',
    keyEn: 'patient-instructions-original/c50f-instructions-en.pdf',
    keyEs: 'patient-instructions-original/c50f-instructions-es.pdf',
  },
  {
    type: 'Customer Instruction Handouts',
    name: 'C50i	',
    model: 'c50i',
    keyEn: 'patient-instructions-original/c50i-instructions-en.pdf',
    keyEs: 'patient-instructions-original/c50i-instructions-es.pdf',
  },
  {
    type: 'Customer Instruction Handouts',
    name: 'Nonin Model 7500	',
    model: 'n7500',
    keyEn: 'patient-instructions-original/n7500-instructions-en.pdf',
    keyEs: 'patient-instructions-original/n7500-instructions-es.pdf',
  },
  {
    type: 'Customer Instruction Handouts',
    name: 'Masimo Rad-8	',
    model: 'rad8',
    keyEn: 'patient-instructions-original/rad8-instructions-en.pdf',
    keyEs: 'patient-instructions-original/rad8-instructions-es.pdf',
  },
  {
    type: 'Customer Instruction Handouts',
    name: 'Masimo Rad-97 (Oximetry)	',
    model: 'rad97',
    subModel: 'POX',
    keyEn: 'patient-instructions-original/rad97-oximetry-instructions-en.pdf',
    keyEs: 'patient-instructions-original/rad97-oximetry-instructions-es.pdf',
  },
  {
    type: 'Customer Instruction Handouts',
    name: 'Masimo Rad-97 (Capnography)	',
    model: 'rad97',
    subModel: 'COX',
    keyEn: 'patient-instructions-original/rad97-capnography-instructions-en.pdf',
    keyEs: 'patient-instructions-original/rad97-capnography-instructions-es.pdf',
  },
  {
    type: 'Customer Instruction Handouts',
    name: 'Nonin LifeSense II	',
    model: 'nlsii',
    keyEn: 'patient-instructions-original/nlsii-instructions-en.pdf',
    keyEs: 'patient-instructions-original/nlsii-instructions-es.pdf',
  },
  {
    type: 'Customer Instruction Handouts',
    name: 'Medtronic Capnostream 35',
    model: 'capnostream35',
    keyEn: 'patient-instructions-original/capnostream35-instructions-en.pdf',
    keyEs: '',
  },
  {
    type: 'Blank Forms',
    name: 'Blank Assignment of Benefits (AOB)',
    keyEn: 'aob/aob-form-blank.html',
    keyEs: 'aob/aob-form-blank-es.html',
  },
  {
    type: 'Blank Forms',
    name: 'Blank Prescription (RX) Form for Pulse Oximetry',
    keyEn: 'rx/rx-form-oximetry-blank.pdf',
    keyEs: '',
    officeInfoType: 'POX_COX_RX',
  },
  {
    type: 'Blank Forms',
    name: 'Blank Prescription (RX) Form for Capnography',
    keyEn: 'rx/rx-form-capnography-blank.pdf',
    keyEs: '',
    officeInfoType: 'POX_COX_RX',
  },
  {
    type: 'Blank Forms',
    name: 'Home Sleep Test Order Form (HST)',
    keyEn: 'rx/rx-form-hst-blank.pdf',
    keyEs: '',
    officeInfoType: 'HST_RX',
  },
  {
    type: 'Other Forms',
    name: 'Order Change Form',
    keyEn: 'misc/order-change-form-en.pdf',
    keyEs: 'misc/order-change-form-es.pdf',
    officeInfoType: 'ORDER_CHANGE',
  },
  {
    type: 'Other Forms',
    name: 'Device Return Form',
    keyEn: 'misc/device-return-form-en.pdf',
    keyEs: '',
    officeInfoType: 'NONE',
  },
]
