import { useState } from 'react'
import { useParams } from 'react-router-dom'

import DobUpsert from './DobUpsert'
import OrderInfo from './OrderInfo'
import Success from './Success'
import VerifyUpsert from './VerifyUpsert'
import * as api from 'helpers/api'
import { useGetPatientOrderQuery, useSignPatientAobMutation } from 'store/services/patientEndpoints'
import { tsOfficePhone } from 'constants/misc'

const Verify = () => {
  const { orderId, aobId } = useParams()
  const [dobVerified, setDobVerified] = useState(false)
  const [error, setError] = useState('')
  const [orderVerified, setOrderVerified] = useState(false)
  const [aobPdf, setAobPdf] = useState<string>()
  const [skip, setSkip] = useState(true)
  const { data: order, isLoading } = useGetPatientOrderQuery(undefined, { skip })
  const [signAob] = useSignPatientAobMutation()

  const verifyDob = async (dob: string) => {
    try {
      setError('')
      const result = await api.get(`/patient/auth?`, { aobId, dob, orderId })
      if (result?.access_token) {
        localStorage.setItem('access_token', result.access_token)
        setSkip(false)
        setDobVerified(true)
      }
    } catch (err) {
      if (err.response.status > 400 && err.response.status < 500) {
        setError(err?.response?.data?.message || 'Invalid date of birth entered.')
      }
      console.log(err)
    }
  }

  return (
    <div className="container" style={{ maxWidth: 720 }}>
      <div className="mb-3">
        <h2>eSign for Oxygen Qualification Testing</h2>
        {!dobVerified ? <DobUpsert verifyDob={verifyDob} /> : null}
        {dobVerified && !orderVerified && !isLoading && order ? (
          <OrderInfo order={order} setOrderVerified={setOrderVerified} />
        ) : null}
        {dobVerified && orderVerified && !aobPdf && order ? (
          <VerifyUpsert order={order} signAob={signAob} setAobPdf={setAobPdf} />
        ) : null}
        {dobVerified && orderVerified && aobPdf ? <Success pdf={aobPdf} /> : null}
        <p className="text-danger">{error}</p>
      </div>
    </div>
  )
}

export default Verify
