/** @jsxImportSource @emotion/react */
import { capitalize } from 'lodash'
import React, { useCallback, useState } from 'react'
import { Order } from 'store/types'
import { css } from '@emotion/react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface DisplayJsonProps {
  data: any
  expandedByDefault?: string[]
}

const validateOptumDate = (date: string): string | null => {
  if (date.length !== 8) {
    return null
  }

  if (!date.startsWith('19') && !date.startsWith('20')) {
    return null
  }

  if (!/^\d+$/.test(date)) {
    return null
  }

  let year = parseInt(date.substring(0, 4))
  let month = parseInt(date.substring(4, 6), 10)
  let day = parseInt(date.substring(6, 8), 10)

  if (month < 1 || month > 12) {
    return null
  }

  if (day < 1 || day > 31) {
    return null
  }

  return new Date(year, month - 1, day).toLocaleDateString()
}

const parseOptumDate = (date: string): string | null => {
  const validatedDate = validateOptumDate(date)

  if (validatedDate) {
    return validatedDate
  }

  const [date1, date2] = date.split('-')

  if (date1 && date2) {
    const validatedDate1 = validateOptumDate(date1)
    const validatedDate2 = validateOptumDate(date2)

    if (validatedDate1 && validatedDate2) {
      return `${validatedDate1} - ${validatedDate2}`
    }
  }

  return null
}

const DisplayJson: React.FC<DisplayJsonProps> = ({ data, expandedByDefault = [] }) => {
  const [collapsedMap, setCollapsedMap] = useState<Record<string, boolean>>({})

  const convertCamelToNormal = (str: string) => {
    const spaced = str.replace(/([A-Z])/g, ' $1').trim()
    return spaced.charAt(0).toUpperCase() + spaced.slice(1)
  }

  const isObject = (val: any) => typeof val === 'object' && val !== null && !Array.isArray(val)

  const renderPrimitiveValue = (val: any) => {
    if (val === null || val === undefined) return '-'
    if (typeof val === 'boolean') return val ? 'Yes' : 'No'
    return parseOptumDate(val.toString()) || val.toString()
  }

  const isCollapsed = useCallback(
    (path: string) => {
      if (path in collapsedMap) {
        return collapsedMap[path]
      }
      return !expandedByDefault.includes(path)
    },
    [collapsedMap, expandedByDefault],
  )

  const toggleCollapse = (path: string) => {
    setCollapsedMap((prev) => ({
      ...prev,
      [path]: !isCollapsed(path),
    }))
  }

  const renderField = (key: string, value: any, path: string) => {
    const displayKey = convertCamelToNormal(key)
    const collapsed = isCollapsed(path)

    const headerRowStyle = css({
      padding: '10px 10px',
      borderBottom: '1px solid #dddddd',
      fontSize: 12,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
    })

    // 1) If it's an array
    if (Array.isArray(value)) {
      return (
        <div key={path}>
          <div css={headerRowStyle} onClick={() => toggleCollapse(path)}>
            <div>{displayKey}</div>
            {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </div>
          {/* If collapsed, do not render children. No animation here. */}
          {!collapsed && (
            <div style={{ marginLeft: 20 }}>
              {value.map((item, index) => {
                const itemPath = `${path}.${index}`
                return (
                  <div key={itemPath} style={{ marginBottom: 10 }}>
                    <div style={{ fontSize: 12, fontWeight: 'bold', paddingTop: 10 }}>
                      {index + 1}.
                    </div>
                    {isObject(item) || Array.isArray(item) ? (
                      <div style={{ marginLeft: 20 }}>{renderObjectOrArray(item, itemPath)}</div>
                    ) : (
                      <div
                        css={{
                          padding: '10px 10px',
                          borderBottom: '1px solid #efefef',
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <div
                          css={{
                            color: 'grey',
                            fontWeight: 600,
                            fontSize: 12,
                            width: '50%',
                          }}>
                          Value
                        </div>
                        <div css={{ fontSize: 12, width: '50%' }}>{renderPrimitiveValue(item)}</div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )
    }

    if (isObject(value)) {
      return (
        <div key={path}>
          <div css={headerRowStyle} onClick={() => toggleCollapse(path)}>
            <div>{displayKey}</div>
            {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </div>
          {!collapsed && <div style={{ marginLeft: 20 }}>{renderObjectOrArray(value, path)}</div>}
        </div>
      )
    }

    return (
      <div
        key={path}
        css={{
          padding: '10px 10px',
          borderBottom: '1px solid #ebebeb',
          display: 'flex',
          alignItems: 'center',
        }}>
        <div
          css={{
            color: 'grey',
            fontWeight: 600,
            fontSize: 12,
            width: '50%',
          }}>
          {displayKey}
        </div>
        <div
          css={{
            fontSize: 12,
            width: '50%',
          }}>
          {renderPrimitiveValue(value)}
        </div>
      </div>
    )
  }

  const renderObjectOrArray = (obj: any, parentPath: string) => {
    if (Array.isArray(obj)) {
      return renderField(parentPath, obj, parentPath)
    }

    return Object.entries(obj).map(([key, val]) => {
      const path = parentPath ? `${parentPath}.${key}` : key
      return renderField(key, val, path)
    })
  }

  return <div>{renderObjectOrArray(data, '')}</div>
}

export const OptumInsuranceData = ({ order }: { order: Order }) => {
  const status = order?.insuranceVerificationStatus

  return (
    <div
      css={{
        minHeight: 100,
      }}>
      {status === 'PENDING' ? (
        <div
          style={{
            padding: 20,
          }}>
          <div
            css={{
              textAlign: 'center',
              padding: 20,
              overflowY: 'scroll',
              overflowX: 'hidden',
              whiteSpace: 'pre-wrap',
            }}>
            Insurance data is being fetched from Optum. Please wait...
          </div>
        </div>
      ) : (
        (order?.insuranceVerificationData || [])
          .slice(0, order?.payers?.length || 0)
          .map((x, i) => {
            const { x12, meta, provider, ...data } = x.rawResponse || {}
            return (
              <div
                css={{
                  margin: 10,
                  padding: 10,
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  whiteSpace: 'pre-wrap',
                  background: '#f3f3f3',
                  borderRadius: 10,
                  marginBottom: 20,
                  border: '1px solid #dddddd',
                }}>
                <div
                  css={{
                    padding: 20,
                    background: '#fff',
                    borderRadius: 6,
                    marginBottom: 20,
                    fontSize: 20,
                  }}>
                  {i + 1}. {capitalize(x.type)} Payer
                </div>
                <div>
                  {x.status === 'PENDING' ? (
                    <div
                      css={{
                        fontStyle: 'italic',
                        padding: 20,
                        color: '#ababab',
                        background: '#ffffff',
                        borderRadius: 6,
                      }}>
                      Data is being fetched from Optum. Please wait...
                    </div>
                  ) : x.error || !x?.rawResponse ? (
                    <div
                      css={{
                        padding: 20,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        whiteSpace: 'pre-wrap',
                        background: '#ffd8d8',
                        borderRadius: 6,
                      }}>
                      <b>There was an error fetching data for {capitalize(x.type)} Payer</b>
                      <br />
                      {x?.error || 'Unknown error'}
                    </div>
                  ) : (
                    <DisplayJson
                      data={data}
                      expandedByDefault={[
                        'subscriber',
                        'subscriber.address',
                        'payer',
                        'planInformation',
                        'planDateInformation',
                      ]}
                    />
                  )}
                </div>
              </div>
            )
          })
      )}
    </div>
  )
}
